import React from 'react';
import Page from "../../organisms/Page";
import Boxes from "../../molecules/ExerciseBoxes"
import FeaturedCard from "../../molecules/FeaturedCard";
const Info = () => {
    return (
        <Page layout="public">
            <section name="main">
                <div className="bg-light p-4 rounded mt-4">
                    <h2 className="text-center">🌞 Morning Rituals: Start Your Day with Energy 🌞</h2>
                    <p>How you begin your day sets the tone for everything that follows. With the S-Carb Diet, we emphasize the importance of morning rituals to kickstart your metabolism, boost your mood, and prepare your body for a day of success.</p>

                    <h3 className="text-center mb-4">🌅 Energizing Morning Rituals:</h3>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Yoga Session</h4>
                                    <p><strong>Duration:</strong> 20-30 minutes</p>
                                    <p><strong>Benefits:</strong> Enhances flexibility, improves posture, and calms the mind.</p>
                                    <p><strong>Routine:</strong> Begin with sun salutations, move to standing poses, and finish with a relaxation pose.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Cycling Workout</h4>
                                    <p><strong>Duration:</strong> 30-45 minutes</p>
                                    <p><strong>Benefits:</strong> Cardiovascular workout that burns calories and strengthens the legs.</p>
                                    <p><strong>Routine:</strong> Start with a warm-up, follow with intervals of high and low intensity, and finish with a cool down.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>These energizing morning rituals are designed to:</p>
                    <ul>
                        <li>🧘 Boost Flexibility: A short yoga session helps improve your body's flexibility and posture, leaving you feeling centered and relaxed.</li>
                        <li>🚴 Increase Cardiovascular Health: Cycling provides a cardio workout that not only burns calories but also strengthens your leg muscles.</li>
                        <li>🌞 Elevate Mood: Starting your day with physical activity releases endorphins, which can enhance your mood and overall well-being.</li>
                        <li>📈 Set the Pace: Establishing a morning routine sets a positive pace for the day, promoting a sense of accomplishment.</li>
                    </ul>
                    <p><span className="fw-bold"><em>Sacred Bonus</em></span>: Your morning ritual is your sanctuary, a sacred space to commune with your highest self. Stand before your mirror—the portal to your soul. Gaze deeply into your own eyes and declare, "I love you!" Repeat it 10 to 25 times, each affirmation accompanied by a life-giving breath. This isn't just an exercise; it's an initiation. A promise to your innermost being that you'll honor, love, and cherish yourself, not just today but every day. Unleash the divine within!</p>
                    <p>Remember, your morning rituals are a personal journey. You can adapt these activities to suit your preferences and schedule. The key is to prioritize self-care and start your day on the right foot.</p>
                </div>
                <FeaturedCard keyword="ex-morning" limit="4"/>
                <Boxes/>
            </section>
        </Page>
    );
};

export default Info;