import React from 'react';
import Page from "../../organisms/Page";
import Boxes from "../../molecules/ExerciseBoxes"
import FeaturedCard from "../../molecules/FeaturedCard";
const Info = () => {
    return (
        <Page layout="public">
            <section name="main">
                <div className="bg-light p-4 rounded mt-4">
                    <h2 className="text-center">🌙 Evening Wind Down: Time to Relax and Unwind 🌙</h2>
                    <p>Evening wind down is an essential part of your S-Carb Diet journey. It's the time to relax, release tension, and prepare your body and mind for a restful night's sleep. This phase focuses on stretching and mindfulness, helping you achieve a sense of calm and balance.</p>

                    <h3 className="text-center mb-4">🌠 Wind Down and Relax for the Night</h3>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">🧘‍♂️ Stretching</h4>
                                    <p><strong>Duration:</strong> 10-15 minutes</p>
                                    <p><strong>Benefits:</strong> Relieves muscle tension, increases flexibility, and prepares the body for rest.</p>
                                    <p><strong>Routine:</strong> Focus on major muscle groups. Hold each stretch for 15-30 seconds.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">🧘‍♀️ Mindfulness & Meditation</h4>
                                    <p><strong>Duration:</strong> 10-15 minutes</p>
                                    <p><strong>Benefits:</strong> Reduces stress, improves sleep quality, and centers the mind.</p>
                                    <p><strong>Routine:</strong> Find a quiet spot, sit comfortably, focus on your breath, and clear your mind.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Evening wind down rituals have several advantages:</p>
                    <ul>
                        <li>🧘‍♂️ Relieve Muscle Tension: Stretching helps relax your muscles and alleviate tension built up during the day.</li>
                        <li>🌙 Promote Better Sleep: Mindfulness and meditation calm your mind, making it easier to fall asleep and improve sleep quality.</li>
                        <li>🧘‍♀️ Center Your Mind: Evening wind down practices help you find balance and serenity, reducing stress and anxiety.</li>
                        <li>🌌 Prepare for a Restful Night: A relaxed evening routine sets the stage for a peaceful and restorative sleep.</li>
                    </ul>

                    <p><span className="fw-bold"><em>Twilight Wisdom</em></span>: The sun sets, and so should your worries. You've heard it said never to go to sleep upset or with unfinished business. But let's transcend that notion. The reality is, life is an ever-changing tapestry of experiences; some threads are loose, some are tight. It's okay to leave some things undone. Your mind is a wondrous realm, capable of solving problems, even as you sleep. Trust in your subconscious to work out the details as you rest.</p>

                    <p>The universe spins on, whether we fret or not. So, why not choose serenity? Release the events of today; they're already in the past. Surrender them to the night and welcome the repose you not only crave but deeply deserve.</p>

                    <p>Think of sleep as a sanctuary. As you lay down, you're entering a sacred space where your body heals, your mind refreshes, and your soul rejuvenates. Did you know that your brain actually cleanses itself during sleep? Yes, it washes away the detritus of the day, making room for new thoughts, new solutions, new beginnings. This is not just vital for your well-being; it's a non-negotiable for long-term health. Skimping on sleep is like neglecting to take out the trash—eventually, things start to smell, and decay sets in. Sleep deficits are closely linked to conditions like dementia and Alzheimer's disease. So, you're not just "catching some Zs"; you're preserving your cognitive richness, your memories, your very essence.</p>

                    <p>As you close your eyes tonight, remember this: The troubles of today often lose their power in the light of the morning sun. Rest, and let tomorrow come when it will. You'll be ready.</p>

                    <p>Remember to create a tranquil environment for your evening wind down. Dim the lights, play soothing music, and create a calming atmosphere to fully embrace this phase of your S-Carb Diet journey.</p>
                </div>
                <FeaturedCard keyword="ex-evening" limit="4"/>
                <Boxes/>
            </section>
        </Page>
    );
};

export default Info;