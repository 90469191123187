import React from 'react';
import Page from "../../organisms/Page";
import Boxes from "../../molecules/WhatToEatBoxes"
import FeaturedCard from "../../molecules/FeaturedCard";
const Eats = () => {
    const styleBox = {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '15px',
        marginBottom: '20px',
        border: '1px solid #d4d4d4',
        borderRadius: '5px'
    };

    const listItemStyle = {
        margin: '10px 0'
    };
    return (
        <Page layout="public">
            <section name="main">
                <div className="text-center mt-5 mb-5">
                    <h1>Foods with Inflammatory Properties: Best to Consume in Moderation</h1>
                    <p>Discover what foods to avoid or consume in moderation to maintain a healthy and energetic lifestyle.</p>
                </div>

                <div style={styleBox}>
                    <h2>Sugar and High-Fructose Corn Syrup:</h2>
                    <p>Excessive sugar intake can lead to chronic inflammation as it causes spikes in blood sugar levels, which trigger the release of inflammatory cytokines.</p>
                    <ul>
                        <li style={listItemStyle}>Common Foods: Soda, candy, cookies, pastries, sugary cereals, fruit juices.</li>
                        <li style={listItemStyle}>Replacement Foods: Fresh fruits, natural sweeteners, unsweetened beverages.</li>
                    </ul>
                </div>

                <div style={styleBox}>
                    <h2>Trans Fats:</h2>
                    <p>Trans fats are artificial fats created through hydrogenation. They promote inflammation and are linked to various health issues.</p>
                    <ul>
                        <li style={listItemStyle}>Common Foods: Fast food items, margarine, baked goods, some microwave popcorn.</li>
                        <li style={listItemStyle}>Replacement Foods: Healthy fats, whole grains, homemade baked goods using healthier fats.</li>
                    </ul>
                </div>

                <div style={styleBox}>
                    <h2>Refined Carbohydrates:</h2>
                    <p>Refined carbs can cause rapid spikes in blood sugar levels, leading to increased inflammation.</p>
                    <ul>
                        <li style={listItemStyle}>Common Foods: White bread, white rice, sugary cereals, white pasta.</li>
                        <li style={listItemStyle}>Replacement Foods: Whole grains, complex carbs, fiber-rich foods.</li>
                    </ul>
                </div>

                <div style={styleBox}>
                    <h2>Processed Meats:</h2>
                    <p>Processed meats often contain preservatives and additives that can trigger inflammation.</p>
                    <ul>
                        <li style={listItemStyle}>Common Foods: Bacon, sausage, hot dogs, deli meats.</li>
                        <li style={listItemStyle}>Replacement Foods: Lean protein sources, fresh homemade sandwiches.</li>
                    </ul>
                </div>

                <div style={styleBox}>
                    <h2>Saturated Fats:</h2>
                    <p>High intake of saturated fats can contribute to inflammation and is associated with heart disease.</p>
                    <ul>
                        <li style={listItemStyle}>Common Foods: Fatty cuts of meat, full-fat dairy, processed meats.</li>
                        <li style={listItemStyle}>Replacement Foods: Lean cuts of meat, low-fat dairy or alternatives, plant-based fats.</li>
                    </ul>
                </div>

                <div style={styleBox}>
                    <h2>Alcohol:</h2>
                    <p>Excessive alcohol consumption can lead to inflammation and liver damage.</p>
                    <ul>
                        <li style={listItemStyle}>Common Drinks: Beer, wine, liquor, cocktails.</li>
                        <li style={listItemStyle}>Replacement Drinks: Water, herbal teas, sparkling water with citrus.</li>
                    </ul>
                </div>

                <div style={styleBox}>
                    <h2>Artificial Additives:</h2>
                    <p>Some artificial additives may trigger allergic or inflammatory reactions.</p>
                    <ul>
                        <li style={listItemStyle}>Common Foods: Artificial sweeteners, packaged snacks with additives.</li>
                        <li style={listItemStyle}>Replacement Foods: Whole foods, natural sweeteners, homemade snacks.</li>
                    </ul>
                </div>

                <div style={styleBox}>
                    <h2>Omega-6 Fatty Acids:</h2>
                    <p>Excessive omega-6, compared to omega-3, can promote inflammation.</p>
                    <ul>
                        <li style={listItemStyle}>Common Oils: Corn oil, soybean oil, sunflower oil.</li>
                        <li style={listItemStyle}>Replacement Oils: Olive oil, avocado oil, flaxseed oil.</li>
                    </ul>
                </div>

                <div style={styleBox}>
                    <h2>Dairy Products:</h2>
                    <p>Some may be sensitive to dairy proteins, leading to inflammation and digestive issues.</p>
                    <ul>
                        <li style={listItemStyle}>Common Foods: Milk, cheese, yogurt, butter.</li>
                        <li style={listItemStyle}>Replacement Foods: Dairy-free alternatives, lactose-free dairy, plant-based options.</li>
                    </ul>
                </div>

                <div style={styleBox}>
                    <h2>Gluten:</h2>
                    <p>For those with gluten sensitivity or celiac, gluten can trigger inflammation.</p>
                    <ul>
                        <li style={listItemStyle}>Common Foods: Wheat-based products, barley, rye, many processed foods.</li>
                        <li style={listItemStyle}>Replacement Foods: Gluten-free grains, gluten-free flour, naturally gluten-free foods.</li>
                    </ul>
                </div>
                <FeaturedCard keyword="wte-inflammatory-foods" limit="4"/>
                <Boxes/>
            </section>
        </Page>
    );
};

export default Eats;