import React, {useContext, useEffect, useState} from 'react';
import {ProductsContext} from '../pages/Products';
import ProductCard from '../molecules/ProductCard'
import Pagination from "./Pagination";

const ProductList = ({onSortChange}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 9;
    const {filteredProducts, setSortOption} = useContext(ProductsContext);
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const data = Array.isArray(filteredProducts) ? filteredProducts : [];
    const currentProducts = data.slice(indexOfFirstProduct, indexOfLastProduct);
    const handlePaginationClick = (page) => {
        setCurrentPage(page);
        setTimeout(() => {
            window.scrollTo(0, 500);
        }, 25);
    };
    useEffect(() => {
        setCurrentPage(1);
        setTimeout(() => {
            window.scrollTo(0, 500);
        }, 25);
    }, [filteredProducts, setCurrentPage]);
    const handleSortChange = (e) => {
        const value = e.target.value;
        setSortOption(value);
    };


    return (
        <div className="container mt-3">
            <div className="row">
                <div className="col-md-12">
                    {/* Product List */}
                    {data.length === 0 ? (
                        <div className="alert alert-light">No products found.</div>
                    ) : (
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3 col-md-3 float-end">
                                        <label htmlFor="sortDropdown" className="form-label">Sort by</label>
                                        <select className="form-select" id="sortDropdown" onChange={handleSortChange}>
                                            <option value="relevance">Relevance</option>
                                            <option value="name">Name</option>
                                            <option value="price">Price</option>
                                            <option value="brand">Brand</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="container">
                                    <div className="row">
                                        {currentProducts.map((product, index) =>  (
                                           <ProductCard key={index} product={product} classNames={'col-md-12 col-lg-4 col-xl-4 col-xxl-4'}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-12">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={Math.ceil(data.length / productsPerPage)}
                        onPageChange={handlePaginationClick}
                    />
                </div>
            </div>

        </div>
    );
};

export default ProductList;
