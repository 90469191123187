import React, { useState, useEffect, useRef } from 'react';

function ExpandableHeaderText({ text, lead, message, headerTag = 'h5' }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const messageRef = useRef(null);

    const HeaderTag = headerTag;

    function containsHTMLTags(str) {
        const doc = new DOMParser().parseFromString(str, 'text/html');
        return Array.from(doc.body.childNodes).some(node => node.nodeType === 1); // Check if any child nodes are of type Element
    }

    const toggleExpand = (e) => {
        e.stopPropagation();
        setIsExpanded(prevState => {
            if (!prevState) { // if expanding
                setTimeout(() => {
                    messageRef.current.scrollIntoView({ behavior: 'smooth' });
                }, 0);
            }
            return !prevState;
        });
    };
    useEffect(() => {
        console.log('isExpanded changed to:', isExpanded);
    }, [isExpanded]);
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                setIsExpanded(false);
            }
        };

        const handleClickOutside = (e) => {
            if (messageRef.current && !messageRef.current.contains(e.target)) {
                setIsExpanded(false);
            }
        };

        document.addEventListener('keydown', handleEscape);
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('keydown', handleEscape);
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div>
            {text && <HeaderTag>{text}</HeaderTag>}
            {lead && containsHTMLTags(lead) ?
                <div className="lead" dangerouslySetInnerHTML={{ __html: lead }}></div> :
                <p className="lead mb-0">{lead}</p>
            }
            {isExpanded && message &&
                <div ref={messageRef} style={{ position: 'relative' }}>
                    {message}
                    <button
                        onClick={() => setIsExpanded(false)}
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            background: 'transparent',
                            border: 'none',
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            padding: '10px',
                            lineHeight: '1'
                        }}
                    >
                        &times;
                    </button>
                </div>
            }
            <p onClick={toggleExpand} className="lead small" style={{ fontSize: '1rem' }}>{isExpanded ? "" : <span style={{ fontSize: '.8rem', cursor: 'pointer' }}>Read More...</span>}</p>
        </div>
    );
}

export default ExpandableHeaderText;
