import {useLocation, Link, NavLink} from 'react-router-dom';
import '../../styles/header.css';
import React, {useContext} from "react";
import {NotFoundContext} from "../../routers/Router";

export default () => {
    const location = useLocation();
    const baseHeaderStyle = {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '600px',
        width: '100%'
    };

    const logo = "/assets/img/shiftcarblogo.png";
    const textlogo = "/assets/img/logo-in-sun.png";

    const headerStyle2 = {
        ...baseHeaderStyle,
        backgroundImage: `url(/assets/img/beach.png)` // Adjust the path accordingly
    };

    const headerStyle3 = {
        ...baseHeaderStyle,
        backgroundImage: `url(/assets/img/what-to-eat.png)` // Adjust the path accordingly
    };

    const headerStyle4 = {
        ...baseHeaderStyle,
        backgroundImage: `url(/assets/img/pineapple-header.png)` // Adjust the path accordingly
    };

    const headerStyle5 = {
        ...baseHeaderStyle,
        backgroundImage: `url(/assets/img/exercise.png)` // Adjust the path accordingly
    };
    const headerStyle6 = {
        ...baseHeaderStyle,
        height: '5rem',
        backgroundImage: `url(/assets/img/exercise.png)`, // Adjust the path accordingly
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
    const baseLogoStyle = {
        height: 'auto',
        display: 'block',
        margin: '0% auto', // Centers the image horizontally
        position: 'relative',
        border: 'none',
        background: 'none',
        zIndex: 2 // Ensures the image appears above the text
    };

    const logoInSunHome = {
        ...baseLogoStyle,
        backgroundColor: "white",
        width: '400px' // Adjust as needed
    };

    const logoInSun = {
        ...baseLogoStyle,
        backgroundColor: "white",
        width: '200px' // Adjust as needed
    };
    const logo2 = {
        ...baseLogoStyle,
        backgroundColor: "white",
        width: '200px' // Adjust as needed
    };
    const logoInProducts = {
        ...baseLogoStyle,
        backgroundColor: "white",
        width: '200px', // Adjust as needed
    };
    const logoInSunText = {
        ...baseLogoStyle,

        width: '5rem', // Adjust as needed
        margin: '0 0 0 0',
        padding: '0 0 0 0'
    };

    // Use the NotFoundContext to check if it's a 404 page
    const {is404} = useContext(NotFoundContext);
    const supportButtonStyle = {
        backgroundColor: '#FFD700',
        borderColor: '#FFD700',
        color: '#333',
        borderRadius: '5px',
        padding: '8px 20px',
        marginLeft: '10px',
        textDecoration: 'none',
        fontWeight: 'bold',
        display: 'inline-block',   // Add this line to ensure consistent block-level behavior
        lineHeight: '1.5',         // Adjust line height for better vertical alignment
        verticalAlign: 'middle'   // Add this line to center align text within the button
    };

    const navBar = (
        <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
            <div className="container px-5">
                <Link className="navbar-brand" to={`/`} style={{color:"#58908F"}}> <img src={textlogo} className="bg-circle" style={logoInSunText} alt="Shift Carb Diet Logo"/></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                        aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <NavLink to="/how-to" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>How to</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/what-to-eat" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>What to eat</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/exercise" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Exercise</NavLink>
                        </li>
                        {/* Modified Products page link */}
                        <li className="nav-item">
                            <NavLink to="/shop" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>Shop Essentials</NavLink>
                        </li>
                        {/* Modified Support button with a new color */}
                        <li className="nav-item">
                            <NavLink to="/support-us" className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} style={supportButtonStyle}>Support</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );

    switch (true) {
        case location.pathname.startsWith('/info') || location.pathname.startsWith('/support-us') || is404:
            return (
                <>
                    {/* Your navigation bar */}
                    {navBar}
                    {/* Info page or 404 page header */}
                    <header className="masthead text-center text-white" style={headerStyle4}>
                        <div className="container px-5">
                            <img src={logo} className="bg-circle" style={logoInSun}
                                 alt="Shift Carb Diet Logo"/>
                        </div>
                    </header>
                </>
            );
        case location.pathname === '/':
            return (
                <>
                    {/* Your navigation bar */}
                    {navBar}
                    {/* Home page header */}
                    <header className="masthead text-center text-white">
                        <div className="masthead-content">
                            <div className="container px-1">
                                <img src={logo} className="bg-circle" style={logoInSunHome}
                                     alt="Shift Carb Diet Logo"/>
                            </div>
                        </div>
                        <div className="bg-circle-1 bg-circle"></div>
                        <div className="bg-circle-2 bg-circle"></div>
                        <div className="bg-circle-3 bg-circle"></div>
                        <div className="bg-circle-4 bg-circle"></div>
                    </header>
                </>
            );
        case location.pathname.startsWith('/how-to'):
            return (
                <>
                    {/* Your navigation bar */}
                    {navBar}
                    {/* How-to page header */}
                    <header className="masthead text-center text-white" style={headerStyle2}>
                        <div className="container px-5">
                            <img src={logo} className="bg-circle" style={logoInSun}
                                 alt="Shift Carb Diet Logo"/>
                        </div>
                    </header>
                </>
            );
        case location.pathname.startsWith('/what-to-eat'):
            return (
                <>
                    {/* Your navigation bar */}
                    {navBar}
                    {/* What-to-eat page header */}
                    <header className="masthead text-center text-white" style={headerStyle3}>
                        <div className="container px-5">
                            <img src={logo} className="bg-circle" style={logoInSun}
                                 alt="Shift Carb Diet Logo"/>
                        </div>
                    </header>
                </>
            );
        case location.pathname.startsWith('/exercise'):
            return (
                <>
                    {/* Your navigation bar */}
                    {navBar}
                    {/* What-to-eat page header */}
                    <header className="masthead text-center text-white" style={headerStyle5}>
                        <div className="container px-5">
                            <img src={logo} className="bg-circle" style={logo2}
                                 alt="Shift Carb Diet Logo"/>
                        </div>
                    </header>
                </>
            );
        case location.pathname.startsWith('/shop'):
            return (
                <>
                    {/* Your navigation bar */}
                    {navBar}
                    {/* What-to-eat page header */}
                    <header className="masthead text-center text-white" style={headerStyle6}>
                        <div className="container px-5">
                            <img src={logo} className="bg-circle" style={logoInProducts}
                                 alt="Shift Carb Diet Logo"/>
                        </div>
                    </header>
                </>
            );
        default:
            return (
                <>
                    {/* Your navigation bar */}
                    {navBar}
                </>
            );
    }
}
