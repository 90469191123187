import React from 'react';
import { Outlet } from 'react-router-dom';
import Page from "../../organisms/Page";
import Boxes from "../../molecules/ExerciseBoxes"
const Info = () => {
    return (
        <Page layout="public">
            <section name="main">
                {/* Cards Section */}
                <Boxes/>
            </section>
        </Page>
    );
};

export default Info;