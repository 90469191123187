import React from 'react';
import { Outlet } from 'react-router-dom';
import Page from "../organisms/Page";

const Info = () => {
	return (
		<Page layout="public">
			<section name="main">
				<Outlet />
			</section>
		</Page>
	);
};

export default Info;