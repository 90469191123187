import React, { useState, useContext } from 'react';
import { ProductsContext } from '../pages/Products';

const Sidebar = () => {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function capitalizeIfNotDot(name) {
        return name.startsWith('.') ? name.substring(1) : capitalizeFirstLetter(name);
    }
    const { products =[], filteredProducts =[], handleFilterChange, setSearchTerm } = useContext(ProductsContext);

    const [isOpen, setIsOpen] = useState({
        price: true,
        category: true,
        brand: true
    });

    const toggleAccordion = (section) => {
        setIsOpen((prevState) => ({ ...prevState, [section]: !prevState[section] }));
    };

    // Extract unique categories and brands from products for the filter checkboxes
    const categories = [...new Set(filteredProducts.map(product => product.category))];

// Create an object with the counts of each brand
    const brandCounts = products.reduce((counts, product) => {
        // Remove the leading dot if present for counting
        const brand = product.brand.startsWith('.') ? product.brand.substring(1) : product.brand;
        counts[brand] = (counts[brand] || 0) + 1;
        return counts;
    }, {});

// Extract unique brands, create objects with name, value, and count
    const brands = [...new Set(products.map(product => product.brand))]
        .map(brand => {
            // Remove the leading dot if present for the name
            const nameWithoutDot = brand.startsWith('.') ? brand.substring(1) : brand;
            return {
                value: brand, // The original brand string, used for filtering/matching
                name: capitalizeIfNotDot(brand), // The display name, with the first letter capitalized if there's no leading dot
                count: brandCounts[nameWithoutDot] // The count of products for the brand
            };
        })
        .sort((a, b) => a.name.localeCompare(b.name)); // Sort by name alphabetically

    return (
        <div className="mt-5 position-sticky top-100 bottom-50 mb-5">
            <h4 className="mb-3">Search</h4>
            <input
                type="text"
                className="form-control mb-4"
                placeholder="Search..."
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <h4 className="mb-3">Filters</h4>

            {/* Price Filter */}
            <div className="accordion mb-3" id="priceAccordion" >
                <div className="accordion-item">
                    <h2 className="accordion-header" id="priceHeading">
                        <button style={{ backgroundColor: '#cfe2ff', color: 'black' }}
                            className="accordion-button"
                            type="button"
                            onClick={() => toggleAccordion('price')}
                            aria-expanded={isOpen.price}
                            aria-controls="priceCollapse"
                        >
                            Price
                        </button>
                    </h2>
                    <div
                        id="priceCollapse"
                        className={`accordion-collapse collapse ${isOpen.price ? 'show' : ''}`}
                        aria-labelledby="priceHeading"
                    >
                        <div className="accordion-body">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="all" id="allPrices" name="price" onChange={handleFilterChange} />
                                <label className="form-check-label" htmlFor="allPrices">All Prices</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="under25" id="under25" name="price" onChange={handleFilterChange} />
                                <label className="form-check-label" htmlFor="under25">Under $25</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="25to50" id="25to50" name="price" onChange={handleFilterChange} />
                                <label className="form-check-label" htmlFor="25to50">$25 to $50</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="50to100" id="50to100" name="price" onChange={handleFilterChange} />
                                <label className="form-check-label" htmlFor="50to100">$50 to $100</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="above100" id="above100" name="price" onChange={handleFilterChange} />
                                <label className="form-check-label" htmlFor="above100">Above $100</label>
                            </div>
                            {/* You can add more price range checkboxes here if needed */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Category Filter */}
            <div className="accordion mb-3" id="categoryAccordion">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="categoryHeading">
                        <button style={{ backgroundColor: '#cfe2ff', color: 'black' }}
                            className="accordion-button"
                            type="button"
                            onClick={() => toggleAccordion('category')}
                            aria-expanded={isOpen.category}
                            aria-controls="categoryCollapse"
                        >
                            Category
                        </button>
                    </h2>
                    <div
                        id="categoryCollapse"
                        className={`accordion-collapse collapse ${isOpen.category ? 'show' : ''}`}
                        aria-labelledby="categoryHeading"
                    >
                        <div className="accordion-body" style={{ maxHeight: 'calc(15rem - 10px)', overflowY: 'scroll', scrollbarWidth: 'thin' }}>
                        {categories.map(cat => (
                                <div className="form-check" key={cat}>
                                    <input className="form-check-input" type="checkbox" value={cat} id={`category-${cat}`} name="category" onChange={handleFilterChange} />
                                    <label className="form-check-label" htmlFor={`category-${cat}`}>{capitalizeFirstLetter(cat)}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Brand Filter */}
            <div className="accordion mb-3" id="brandAccordion">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="brandHeading">
                        <button style={{ backgroundColor: '#cfe2ff', color: 'black' }}
                            className="accordion-button"
                            type="button"
                            onClick={() => toggleAccordion('brand')}
                            aria-expanded={isOpen.brand}
                            aria-controls="brandCollapse"
                        >
                            Brand
                        </button>
                    </h2>
                    <div
                        id="brandCollapse"
                        className={`accordion-collapse collapse  mb-1 ${isOpen.brand ? 'show' : ''}`}
                        aria-labelledby="brandHeading"
                    >
                        <div className="accordion-body" style={{ maxHeight: 'calc(15rem - 10px)', overflowY: 'scroll', scrollbarWidth: 'thin' }}>
                            {brands.map(brand => (
                                <div className="form-check" key={brand.name}>
                                    <input className="form-check-input" type="checkbox" value={brand.value} id={`brand-${brand.name}`} name="brand" onChange={handleFilterChange} />
                                    <label className="form-check-label" htmlFor={`brand-${brand.name}`}>{brand.name} <span style={{ fontSize: '0.7rem' }}>({brand.count})</span></label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
