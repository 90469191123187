export const encodeCategoryURL = (input) => {
    // Remove spaces and special characters and replace with dashes
    return input.replace(/[^\w\s-]/g, '').replace(/\s+/g, '-');
};

export const decodeCategoryURL = (input, products) => {
    // Iterate through products to find a matching category
    const matchingCategory = products.find((product) => {
        const encodedCategory = encodeCategoryURL(product.category);
        return encodedCategory === input;
    });

    // If a matching category is found, return its decoded name
    if (matchingCategory) {
        return matchingCategory.category;
    }

    // If no matching category is found, return the input as is
    return input;
};

// Encode brand name for URL
export const encodeBrandURL = (input) => {
    // Remove spaces and special characters and replace with dashes
    return input.replace(/[^\w\s-]/g, '').replace(/\s+/g, '-');
};

// Decode brand name from URL
export const decodeBrandURL = (input, products) => {
    // Iterate through products to find a matching brand
    const matchingBrand = products.find((product) => {
        const encodedBrand = encodeBrandURL(product.brand);
        return encodedBrand === input;
    });

    // If a matching brand is found, return its decoded name
    if (matchingBrand) {
        return matchingBrand.brand;
    }

    // If no matching brand is found, return the input as is
    return input;
};