import React, {useReducer, Children, useContext, useEffect, useMemo} from "react";
import PublicLayout from "../components/layouts/Public";

export const LayoutContext = React.createContext(false);
export const reducer = (state, action) => {
    switch (action.type) {
        case "ADD_LAYOUT":
            return {
                ...state,
                layouts: {...state.layouts, ...action.layouts}
            };
        case "GET_LAYOUT":
            return state.layouts;
        case "ADD_SECTION":
            return {
                ...state,
                section: {...state.section, ...action.section}
            };
        case "GET_SECTION":
            return state.section;
        case "ADD_PROGRESS":
            return {
                ...state,
                progress: {...state.progress, ...action.progress}
            };
        default:
            return state;
    }
};

const layouts = {
    public: PublicLayout
};

export const initialState = {
    layouts,
    section: {},
};

export const LayoutProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(() => ({state, dispatch}), [state]);
    return (
        <LayoutContext.Provider value={value}>
            {props.children}
        </LayoutContext.Provider>
    );
};