import React from 'react';
import Header from "../molecules/Header_Public";
import Footer from "../molecules/Footer_Public";
import { CContainer } from "@coreui/react";
import Section from "../atoms/Section";

export default ()=>{
		return (
			<>
				<Section name="public_header">
					<Header/>
				</Section>
				<CContainer>
					<Section name="main"/>
				</CContainer>
				<Section name="public_footer">
					<Footer/>
				</Section>
			</>
		);
}