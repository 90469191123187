import Page from "../organisms/Page";
import ProductList from "../molecules/ProductList";
import React, {useState, useEffect, createContext} from 'react';
import Sidebar from "../molecules/ProductSidebar";
import {fetchDataFiles} from "../../utility/fetchData";
import ExpandableHeaderText from "../molecules/ExpandableHeaderText";
import { useParams } from 'react-router-dom';
import { encodeCategoryURL, decodeCategoryURL, decodeBrandURL, encodeBrandURL } from '../../utility/permalinks';
// Create the context
export const ProductsContext = createContext([]);

const Products = () => {
    const { categoryName, brandName } = useParams();
    const [sortOption, setSortOption] = useState("relevance"); // default sorting option
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [activeFilters, setActiveFilters] = useState({
        price: [],
        category: [],
        brand: []
    });
    const [currentPage, setCurrentPage] = useState(1);
    const handleFilterChange = (e) => {
        const {name, value, checked} = e.target;

        // Handle price filter
        if (name === "price") {
            if (value === "all") {
                setActiveFilters(prevFilters => ({...prevFilters, price: checked ? ["all"] : []}));
            } else {
                setActiveFilters(prevFilters => {
                    if (checked) {
                        // Add the selected price range to the array if checked
                        return {...prevFilters, price: [...prevFilters.price.filter(p => p !== "all"), value]};
                    } else {
                        // Remove the selected price range from the array if unchecked
                        return {...prevFilters, price: prevFilters.price.filter(p => p !== value)};
                    }
                });
            }
        }
        // Handle category and brand filters
        else {
            if (checked) {
                setActiveFilters(prevFilters => ({...prevFilters, [name]: [...prevFilters[name], value]}));
            } else {
                setActiveFilters(prevFilters => ({
                    ...prevFilters,
                    [name]: prevFilters[name].filter(item => item !== value)
                }));
            }
        }
        setCurrentPage(1);
    };

    const setSearchTerm = (term) => {
        const lowerCaseTerm = term.toLowerCase();
        const newFilteredProducts = products.filter(product => {
            // Title match
            const titleMatch = product.name.toLowerCase().includes(lowerCaseTerm);

            // Description match
            const descriptionMatch = product.description && product.description.toLowerCase().includes(lowerCaseTerm);

            // Keywords match
            const keywordsArray = product.keywords ? product.keywords.split(',').map(keyword => keyword.trim().toLowerCase()) : [];
            const keywordsMatch = keywordsArray.some(keyword => keyword.includes(lowerCaseTerm));

            return titleMatch || descriptionMatch || keywordsMatch;
        });

        setFilteredProducts(newFilteredProducts);
    };

    const applyFilters = () => {
        // Unified price condition function
        const priceFilter = product => {
            const price = parseFloat(product.price.replace(/[^\d.-]/g, '').trim());
            return (
                (!activeFilters.price.length || activeFilters.price.includes("all")) ||
                (activeFilters.price.includes('under25') && price < 25) ||
                (activeFilters.price.includes('25to50') && price >= 25 && price <= 50) ||
                (activeFilters.price.includes('50to100') && price >= 50 && price <= 100) ||
                (activeFilters.price.includes('above100') && price > 100)
            );
        };

        // Unified function for category and brand filters
        const otherFilter = (product, filterType) => {
            if (!activeFilters[filterType].length) return true;
            return activeFilters[filterType].includes(product[filterType]);
        };

        // Apply all filters
        const newFilteredProducts = products.filter(product =>
            priceFilter(product) &&
            otherFilter(product, 'category') &&
            otherFilter(product, 'brand')
        );

        // Sorting
        // eslint-disable-next-line default-case
        switch (sortOption) {
            case 'name':
                newFilteredProducts.sort((a, b) => a.name.localeCompare(b.name));
                break;
            case 'price':
                newFilteredProducts.sort((a, b) => parseFloat(a.price.replace(/[^\d.-]/g, '').trim()) - parseFloat(b.price.replace(/[^\d.-]/g, '').trim()));
                break;
            case 'brand':
                newFilteredProducts.sort((a, b) => a.brand.localeCompare(b.brand));
                break;
        }

        // Set the final filtered products
        setFilteredProducts(newFilteredProducts);
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchDataFiles();
            setProducts(data);
            setFilteredProducts(data);  // Initially, all products are shown
        }

        fetchData();
    }, []);

    useEffect(() => {
        applyFilters(); // Apply filters and sort whenever activeFilters or sortOption change
    }, [activeFilters, sortOption]);
    // Check if the categoryName matches an existing category or if it's the default "/shop"

    useEffect(() => {
        if (categoryName && products.length > 0) {
            const existingCategory = products.find((product) => {
                // Try to match both encoded and decoded category names
                const encodedCategory = encodeCategoryURL(product.category);
                console.log('encodedCategory', encodedCategory);
                return (
                    encodedCategory.toLowerCase() === categoryName.toLowerCase() ||
                    product.category.toLowerCase() === categoryName.toLowerCase()
                );
            });
            console.log('existingCategory', existingCategory);

            if (existingCategory) {
                const decodedCategoryName = decodeCategoryURL(existingCategory.category, products);
                console.log('decodedCategoryName', decodedCategoryName);
                setActiveFilters((prevFilters) => ({
                    ...prevFilters,
                    category: [decodedCategoryName],
                }));

                // Check the checkbox for the matched category
                const checkbox = document.querySelector(
                    `input[name="category"][value="${decodedCategoryName}"]`
                );
                if (checkbox) {
                    checkbox.checked = true;
                }
            }
        }
    }, [categoryName, products]);

    useEffect(() => {
        if (brandName && products.length > 0) {
            const existingBrand = products.find((product) => {
                // Try to match both encoded and decoded brand names
                const encodedBrand = encodeBrandURL(product.brand);
                return (
                    encodedBrand.toLowerCase() === brandName.toLowerCase() ||
                    product.brand.toLowerCase() === brandName.toLowerCase()
                );
            });

            if (existingBrand) {
                const decodedBrandName = decodeBrandURL(existingBrand.brand, products);
                setActiveFilters((prevFilters) => ({
                    ...prevFilters,
                    brand: [decodedBrandName],
                }));

                // Check the checkbox for the matched brand
                const checkbox = document.querySelector(
                    `input[name="brand"][value="${decodedBrandName}"]`
                );
                if (checkbox) {
                    checkbox.checked = true;
                }
            }
        }
    }, [brandName, products]);


    const message = (<React.Fragment>
            <div className="bg-light p-4 rounded mt-4">
                <h3 className="text-center">🌱 Principles of Our Curated Choices 🌱</h3>
                <p className={"text-start"}>Embarking on the S-Carb Diet is a celebration of balance. Our selections
                    reflect this, mirroring life's ebb and flow. 🍲 At the heart of our philosophy is a <strong>Harmonious
                        Balance</strong>. We're passionate about the dance between taste and nutrition. With our
                    products, there's no need to pick one over the other — you can truly have both.</p>

                <p className={"text-start"}>The beauty of our curated choices is that they <strong>Embrace
                    Nature</strong> 🌾. Drawing inspiration from the unadulterated richness of nature and age-old
                    traditions, we've grounded our selections in organic and nourishing ingredients. It's like receiving
                    a wholesome gift from nature, wrapped up and delivered straight to your plate.</p>

                <p className={"text-start"}>Here's something we've always believed: <strong>Living Fully, Living
                    Truly</strong> 💖. We understand the allure of guilty pleasures; after all, who can resist waffles
                    dripping with syrup on a lazy Sunday morning? But life is about striking that golden balance. It's
                    about not letting sugar run our lives, cherishing our vibrant spirit, and setting our sights on
                    aging with grace and fervor. We envision a future where we occasionally indulge without compromise,
                    celebrating life in all its sweet moments.</p>

                <p className={"text-start"}>And finally, every choice we make is driven by <strong>Purifying 🔥
                    Intentions</strong>. Each product we endorse isn't just another item on a shelf. It's a stepping
                    stone on a transformative journey towards a more awakened and balanced version of oneself.</p>
            </div>

        </React.Fragment>
    );
    return (
        <ProductsContext.Provider
            value={{products, filteredProducts, setActiveFilters, handleFilterChange, setSearchTerm, setSortOption}}>
            <Page layout="public">
                <section name="main">
                    <div className="row">
                        <div className="text-center mt-5">
                            <ExpandableHeaderText
                                text="S-Carb's Premium Selections"
                                lead="Explore our handpicked products that complement your fasting voyage!"
                                message={message}
                                headerTag="h1"
                            />
                        </div>
                        <div className="col-md-3">
                            <div className="position-sticky top-0">
                                <Sidebar/>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <ProductList/>
                        </div>
                    </div>
                </section>
            </Page>
        </ProductsContext.Provider>
    );
};

export default Products;
