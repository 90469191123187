import React, { useState, useEffect, createContext } from 'react';
import { fetchDataFiles } from '../../utility/fetchData';
import ProductCard from './ProductCard';

// Create the FeaturedCard context
export const FeaturedCardContext = createContext();

const FeaturedCard = ({ keyword, limit = 4, title = null }) => {
    const [data, setData] = useState([]);
    const [isEmpty, setIsEmpty] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const fetchedData = await fetchDataFiles();
            setData(fetchedData);
            setIsEmpty(false);
        };

        fetchData();
    }, []);

    const filtered = data.filter((product) => {
        if (product.keywords && typeof product.keywords === 'string') {
            const hasKeyword = product.keywords
                .toLowerCase()
                .split(',')
                .map((kw) => kw.trim())
                .includes(keyword.toLowerCase());
            return hasKeyword;
        }
        return false; // Handle the case where product.keywords is not a string
    }).slice(0, limit);

    useEffect(() => {
        setIsEmpty(filtered.length === 0);
    }, [filtered]);

    if (isEmpty) {
        return null;
    }

    return (
        <>
            {title}
            <div className="row mt-5">
                {filtered.map((product, index) => (
                    <ProductCard key={index} product={product} />
                ))}
            </div>
        </>
    );
};

export default FeaturedCard;
