// eslint-disable-next-line
import {
    CLink, CFooter
} from "@coreui/react";
import {useLocation, Link} from "react-router-dom";

export default () => {
    const location = useLocation();

    return <>
        <footer className="py-5 bg-black mt-5">
            <div className="container px-5">
                <p className="m-0 text-center text-white small">Copyright &copy; Shift-Carb Diet 2020-2023</p>
                <p className="m-0 text-center text-white small mb-2">This site promotes affiliate-backed products</p>

                <Link className="nav-link text-white text-center small" to={`/info/tos`}>Terms of Service</Link>
                <Link className="nav-link text-white text-center small" to={`/info/disclaimer`}>Disclaimer</Link>
                <Link className="nav-link text-white text-center small" to={`/info/privacy`}>Privacy</Link>
            </div>
        </footer>
    </>;
}
