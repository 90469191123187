import React from 'react';
import { Outlet } from 'react-router-dom';
import Page from "../../organisms/Page";
import Boxes from "../../molecules/HowToBoxes"
import FeaturedCard from "../../molecules/FeaturedCard";
const Info = () => {
    return (
        <Page layout="public">
            <section name="main">

                {/* Cards Section */}
                <div className="container mt-5">

                    <div className="text-center mb-5">
                        <h1 id={"start"}>🛠️ Tips and Tricks for the S-Carb Diet 🛠️</h1>
                        <p className="lead">Optimize your S-Carb journey with these guidelines and pro-tips.</p>
                    </div>

                    <div className="bg-light p-4 rounded mb-4">
                        <h2 className="text-center">🎉 Maintenance Mode</h2>
                        <p>Reached your weight destination? Kudos! It's time to transition into maintenance mode. Stick with Phase 1 and recalibrate your exercises to sustain your new weight. Now, the focus shifts to building muscle with strength and resistance training.</p>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            🍽️ Dietary Guidelines
                        </div>
                        <div className="card-body">
                            <ul>
                                <li>🥦 Relish the natural goodness of unprocessed foods.</li>
                                <li>🚫 Liquid sugars? No thanks! Skip sodas and sugary drinks.</li>
                                <li>🔎 Be mindful of your cheat snack choices during a fast. Remember, your total daily intake should ideally not exceed 500 calories to honor the fasting process.</li>
                                <li>📊 Target Nutrients: 25% protein, 35% fats, 35% carbs, 5% sugars, and ensure you consume more than 30 grams of fiber.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            🔢 The Math Behind the Magic
                        </div>
                        <div className="card-body">
                            <ul>
                                <li>⌚ Data-Driven Dieting: Utilize devices like the Apple Watch to monitor your caloric expenditure. Establish your off-diet daily calorie burn as a baseline to set precise goals for your on-diet days.</li>
                                <li>📈 Calories and BMI: They're informative, not binding. Use them as reference points.</li>
                                <li>🔥 Burn Baby Burn: Establish a daily calorie-burning goal, aiming for double your typical intake — make sure it's a safe target. Calculate it with a dash of math for accuracy and sprinkle with a dose of common sense for sustainability.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            🌟 Pro-Tips to Elevate Your S-Carb Experience
                        </div>
                        <div className="card-body">
                            <ul className="s-carb-pro-tips">
                                <li>📓 Keep a journal of your diet adventure. Write in it daily on how you feel, what time you feel that way, and why.</li>
                                <li>💧 Hydration Station: Quench your thirst with water, especially when hunger hits around the 15-hour mark.</li>
                                <li>🥤 Hydration Helper: Keep track of your water intake with smart water bottles, ensuring you stay well-hydrated throughout your diet journey.</li>
                                <li>👩‍⚕️ Listen to Your Body: Tweak the plan to accommodate your health needs and preferences.</li>
                                <li>💊 Vitamin Boost: Opt for a multivitamin supplement enriched with electrolytes and amino acids.</li>
                                <li>📊 Stay Informed: Monitor your body's reactions and make guilt-free adjustments. Your S-Carb Diet should be as unique as you are!</li>
                                <li>📉 Track & Triumph: Keep a log of your weight to identify trends and celebrate every ounce of progress!</li>
                                <li>🌡️ Precision Nutrition: Use a digital food scale to measure your portions and ensure you're on track with your calorie goals.</li>
                                <li>🍽️ Smart Eating Kit: Manage your meals with our portion control plates, making it easy to balance your diet with the right amounts of macros.</li>
                                <li>🏋️‍♂️ Fitness Gear: Gear up with on resistance bands and yoga mats to enhance your home workouts and support your S-Carb journey.</li>
                                <li>🍳 Culinary Creations: Explore our S-Carb suggestions on friendly cookbook and kitchen gadgets to craft delicious, diet-compliant meals, available in the shop.</li>
                                <li>💤 Sleep Support: Enhance your rest with sleep masks and calming herbal teas, aiding your body's recovery and weight management.</li>
                                <li>🧘‍♂️ Mindfulness Master: Find your center with meditation cushions and guided sessions to reduce stress and improve dietary success.</li>
                            </ul>
                        </div>
                    </div>

                    <div class="text-center mt-5">
                        <h2>🌈 Embrace the S-Carb Lifestyle!</h2>
                        <p>Use these tips to make the most of your journey, and always remember: You got this!</p>
                    </div>
                    <FeaturedCard keyword="ht-tips" limit="4"/>
                </div>

                <Boxes/>
            </section>
        </Page>
    );
};

export default Info;