import './App.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Router from "./routers/Router";
import React from "react";
import {LayoutProvider} from "./context/LayoutContext";
import './styles/styles.css'; // Adjust the path to point to your CSS file
export default function App() {
  return (
      <LayoutProvider>
          <Router/>
      </LayoutProvider>
  );
}