import Page from "../organisms/Page";
import {Link} from "react-router-dom";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
		<Page layout="public">
			<section name="main">
				<section id="scroll">
					<div className="container px-5">
						<Link to={"/how-to/about"} className="text-black text-decoration-none">
						<div className="row gx-5 align-items-center">
							<div className="col-lg-6 order-lg-2">
								<div className="p-5"><img className="img-fluid rounded-circle" src="assets/img/timed_plate.png
							" alt="..." /></div>
							</div>
							<div className="col-lg-6 order-lg-1">
								<div className="p-5">
									<h2 className="display-4">Interval fasting...</h2>
									<p>Dive into a transformative 36-hour fasting journey, supercharged with tailored exercise regimens. But it's not just about the fast. With Shift Carb, you're not only shedding pounds—you're gaining a lifetime of nutritional wisdom. Harness keto-inspired strategies to melt away fat and then master the art of mindful eating. The result? Sustainable weight loss and a renewed love for food. With Shift Carb, it's not a diet—it's a lifestyle revolution. Every tick of the clock brings you closer to your best self. Start today.</p>
								</div>
							</div>
						</div></Link>
					</div>
				</section>
				<section>
					<div className="container px-5">
						<Link to={"/how-to/36-hour-timeline"} className="text-black text-decoration-none">
						<div className="row gx-5 align-items-center">
							<div className="col-lg-6">
								<div className="p-5"><img className="img-fluid rounded-circle" src="assets/img/meditating.png" alt="..." /></div>
							</div>
							<div className="col-lg-6">
								<div className="p-5">
									<h2 className="display-4">Shift between fasting one day...</h2>
									<p>As night descends, step into the Shift Carb experience. Let sleep be your starting point, setting the tone for a carb and sugar-free day ahead. This 36-hour voyage, beginning one evening and stretching to the dawn of the second day, isn't about deprivation—it's about making smarter choices. While carbs and sugars step back, proteins and healthy fats step up, keeping you nourished within a mindful limit of 500 calories. Dive into this harmonious blend of rest, mindfulness, and optimized nutrition. With Shift Carb, you're not just fasting; you're resetting.</p>
								</div>
							</div>
						</div></Link>
					</div>
				</section>
				<section>
					<div className="container px-5">
						<Link to={"/what-to-eat/healthy-foods"} className="text-black text-decoration-none">
						<div className="row gx-5 align-items-center">
							<div className="col-lg-6 order-lg-2">
								<div className="p-5"><img className="img-fluid rounded-circle" src="assets/img/feast.png" alt="..." /></div>
							</div>
							<div className="col-lg-6 order-lg-1">
								<div className="p-5">
									<h2 className="display-4">...and eating what you want the next day!</h2>
									<p>Throughout history, our ancestors thrived on a natural cycle of famine followed by feast. The Shift Carb regimen revives this age-old rhythm, letting the fast echo the famine phase. But come sunrise, it's time to indulge! Feast responsibly, savoring every bite while adhering to a balanced diet. Aim to meet your calorie goals without compromising on taste. Avoid the pitfalls of modern-day poisons, especially liquid sugars. Strive for a nourishing mix: 25% protein, 35% fats, 35% carbs, a mere 5% sugar, and a healthy dose of 30 grams of fiber. With Shift Carb, it's not just about eating—it's about celebrating food the right way.</p>
								</div>
							</div>
						</div></Link>
					</div>
				</section>
			</section>
		</Page>
);