import React from 'react';
import Page from "../../organisms/Page";
import Boxes from "../../molecules/WhatToEatBoxes"
import FeaturedCard from '../../molecules/FeaturedCard'; // Adjust the path if needed
const Eats = () => {

    const styleBox = {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginBottom: '.5em'
    };

    const cardBodyStyle = {
        flex: '1',
        overflow: 'hidden'
    };
    return (
        <Page layout="public">
            <section name="main">
                <div className="container mt-5">

                    <div className="text-center mb-5">
                        <h1>💣 S-Carb's Ultimate Fat Bomb Collection! 💣</h1>
                        <p className="lead">Whip up these delectable treats to keep your energy high and cravings at
                            bay.</p>
                    </div>

                    <div className="bg-light p-4 rounded mb-4">
                        <h2 className="text-center">🍫 Satisfy Your Taste Buds with These Fat Bomb Delights 🍫</h2>
                    </div>

                    <div className="row">

                        <div className="col-md-3">
                            <div className="card" style={styleBox}>
                                <div className="card-body" style={cardBodyStyle}>
                                    <h4 className="card-title">Chocolate Peanut Butter Bliss</h4>
                                    <ul>
                                        <li>🍫 1/2 cup dark chocolate (unsweetened)</li>
                                        <li>🥜 1/4 cup peanut butter (no sugar added)</li>
                                        <li>🥥 1/4 cup coconut oil</li>
                                        <li>🍯 Optional: A drizzle of stevia for sweetness</li>
                                    </ul>
                                    <p>Melt ingredients, mix, pour into molds, and freeze. Enjoy when solid!</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card" style={styleBox}>
                                <div className="card-body" style={cardBodyStyle}>
                                    <h4 className="card-title">Coconut Almond Delight</h4>
                                    <ul>
                                        <li>🥥 1/2 cup coconut butter</li>
                                        <li>🥜 1/4 cup almond butter</li>
                                        <li>🍫 2 tbsp cacao powder</li>
                                        <li>🍯 Optional: A sprinkle of sea salt</li>
                                    </ul>
                                    <p>Mix ingredients, roll into balls, and freeze. Savor when chilled!</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card" style={styleBox}>
                                <div className="card-body" style={cardBodyStyle}>
                                    <h4 className="card-title">Lemon Cheesecake Bliss</h4>
                                    <ul>
                                        <li>🧀 1/2 cup cream cheese</li>
                                        <li>🍋 Zest and juice of 1 lemon</li>
                                        <li>🍯 2 tbsp stevia or erythritol</li>
                                        <li>🥥 1/4 cup coconut oil</li>
                                    </ul>
                                    <p>Mix ingredients, pour into molds, and freeze. A zesty treat awaits!</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card" style={styleBox}>
                                <div className="card-body" style={cardBodyStyle}>
                                    <h4 className="card-title">Mocha Coffee Bombs</h4>
                                    <ul>
                                        <li>☕ 1/4 cup brewed coffee, cooled</li>
                                        <li>🍫 1/4 cup unsweetened cocoa powder</li>
                                        <li>🍯 2 tbsp stevia or erythritol</li>
                                        <li>🥥 1/4 cup coconut oil</li>
                                    </ul>
                                    <p>Mix ingredients, pour into molds, and freeze. Your coffee fix in a bite!</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card mt-3" style={styleBox}>
                                <div className="card-body" style={cardBodyStyle}>
                                    <h4 className="card-title">Vanilla Cinnamon Delight</h4>
                                    <ul>
                                        <li>🍦 1 tsp vanilla extract</li>
                                        <li>🥥 1/4 cup coconut butter</li>
                                        <li>🍯 2 tbsp stevia or erythritol</li>
                                        <li>🌰 A pinch of cinnamon</li>
                                    </ul>
                                    <p>Mix ingredients, roll into balls, and freeze. A classic taste in every bite!</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card mt-3" style={styleBox}>
                                <div className="card-body" style={cardBodyStyle}>
                                    <h4 className="card-title">Berry Coconut Bites</h4>
                                    <ul>
                                        <li>🍓 1/4 cup mixed berries (blueberries, raspberries)</li>
                                        <li>🥥 1/2 cup coconut flakes</li>
                                        <li>🍯 2 tbsp stevia or erythritol</li>
                                        <li>🥥 1/4 cup coconut oil</li>
                                    </ul>
                                    <p>Mix ingredients, roll into balls, and freeze. Berry delicious!</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card mt-3" style={styleBox}>
                                <div className="card-body" style={cardBodyStyle}>
                                    <h4 className="card-title">Green Tea Matcha Bombs</h4>
                                    <ul>
                                        <li>🍵 1 tsp matcha powder</li>
                                        <li>🥥 1/4 cup coconut oil</li>
                                        <li>🍯 2 tbsp stevia or erythritol</li>
                                        <li>🍦 1 tsp vanilla extract</li>
                                    </ul>
                                    <p>Mix ingredients, pour into molds, and freeze. A refreshing burst of green
                                        tea!</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="card mt-3" style={styleBox}>
                                <div className="card-body" style={cardBodyStyle}>
                                    <h4 className="card-title">Salted Caramel Fudge</h4>
                                    <ul>
                                        <li>🍯 1/4 cup stevia or erythritol (caramel flavored if available)</li>
                                        <li>🧂 A pinch of sea salt</li>
                                        <li>🍫 1/4 cup unsweetened cocoa powder</li>
                                        <li>🥥 1/4 cup coconut oil</li>
                                    </ul>
                                    <p>Melt ingredients, mix, pour into molds, and freeze. A sweet and salty treat!</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <FeaturedCard keyword="Fat Bombs" limit="4" title={<div className="bg-light p-4 rounded mt-5 mb-4">
                        <h2 className="text-center">🍫 Or buy some pre-made delights 🍫</h2>
                    </div>}/>

                    <div className="text-center mt-5">
                        <h3>🍫 Which Fat Bomb Will You Try First? 🍫</h3>
                        <p>Remember, these are just starting points! Feel free to get creative and adjust to your
                            personal taste.</p>
                    </div>

                </div>

                <Boxes/>
            </section>
        </Page>
    );
};

export default Eats;