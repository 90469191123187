// ProductCard.js
import React, {useState} from 'react';
import ProductModal from './ProductModal';
import ExpandableText from "./ExpandableText";

const ProductCard = ({ product, classNames='col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3"' }) => {
    // Split the default classes into an array
    const defaultClassArray = classNames.split(' ');

    // Split the custom className into an array
    const customClassArray = classNames.split(' ');

    // Merge the custom class array with the default class array
    const mergedClassArray = defaultClassArray.map((defaultClass) => {
        // Find the class prefix (e.g., 'col-md') in the default class
        const prefix = defaultClass.match(/^[^\d]+/)[0];

        // Check if there is a class with the same prefix in the custom classes
        const customClassWithPrefix = customClassArray.find(
            (customClass) => customClass.startsWith(prefix)
        );

        // If a matching class exists in custom classes, use it; otherwise, use the default class
        return customClassWithPrefix || defaultClass;
    });

    // Join the merged classes back into a string
    const updatedClassName = mergedClassArray.join(' ');


    const [showModal, setShowModal] = useState(false); // State to handle modal visibility
// ProductCard.js

    const imageContainerStyles = {
        height: "20rem",  // Set fixed height for the image container
        width: '100%',    // Set fixed width for the image container
        overflow: 'hidden', // Ensure that the image does not overflow the container
        position: 'relative'  // Position the container relatively
    };

    const imageStyles = {
        maxHeight: '100%',    // Ensure image does not exceed the container's height
        maxWidth: '100%',     // Ensure image does not exceed the container's width
        display: 'block',     // Display as block to remove any additional spaces
        margin: 'auto',       // Center the image within the container
        objectFit: 'contain',   // Ensure the image covers the container without stretching
        position: 'absolute',  // Absolute position to center the image
        top: '50%',            // Centering adjustments
        left: '50%',
        transform: 'translate(-50%, -50%)'
    };

    const logoStyles = {
        width: '100%',     // Make sure the image takes the full width of the container
        height: '100%',
        backgroundColor: 'grey',
        opacity: 0.3,
    };
    const categoryColors = {
        "Foods & Snacks": "bg-danger",
        "Supplements": "bg-dark",
        "Ketosis Monitoring & Tracking": "bg-secondary",
        "Cookbooks": "bg-light text-dark",
        "Meal Replacement & Shakes": "bg-warning",
        "Kitchen Gadgets & Tools": "bg-info",
        "Educational Materials": "bg-primary",
        "Keto Subscription Boxes": "bg-success",
        "Keto-friendly Condiments & Sauces": "bg-danger",
        "Keto Merchandise": "bg-dark",
        "Fitness & Exercise Gear": "bg-secondary",
        "Apps & Digital Tools": "bg-light text-dark",
        "Keto Community & Support": "bg-warning",
        "Beverages":"bg-info"
    };

    return (
        <div className={`${updatedClassName} mb-4`} >
            <div className="card col shadow-sm mb-4 bg-">
                <a href={product.url} target="_blank" rel="nofollow noopener noreferrer" className="text-decoration-none text-dark">
                    <div className="position-relative" style={imageContainerStyles}>
                        {product.targetPixel &&
                            <img
                                src={product.targetPixel}
                                width="1"
                                height="1"
                                border="0"
                                alt=""
                                style={{border: "none !important", margin: "0px !important"}}
                            />
                        }
                        {product.images && product.images.length > 0 ? (
                            <img src={product.images[0]} className="card-img-top" alt={product.name} style={imageStyles}/>
                        ) : (
                            <img src="assets/img/shiftcarblogo.png" className="card-img-top" alt={product.name} style={logoStyles}/>
                        )}
                        {product.category && <span className={`position-absolute top-0 end-0 mt-2 me-2 badge ${categoryColors[product.category] || 'bg-primary'}`}>{product.category}</span>}
                    </div>
                    {product.site && <div className="text-center bg-light py-1">{product.site}</div>}
                    <div className="card-body">
                        {product.name && <ExpandableText text={product.name} />}
                        {product.brand && <p className="card-subtitle mb-2 text-muted">{product.brand}</p>}

                        {product.price && <p className="font-weight-bold">{product.price}</p>}
                    </div>
                </a>
                <div className="card-footer">
                    <a href={product.url} target="_blank" className="btn btn-block w-100 mb-2" style={{ backgroundColor: '#cfe2ff' }}>Buy Now</a>
                    <button onClick={() => setShowModal(true)} className="btn btn-light w-100">More Info</button>
                </div>
            </div>
            {showModal && <ProductModal product={product} onClose={() => setShowModal(false)} />}
        </div>
    );
}

export default ProductCard;
