import React from 'react';
import Page from "../../organisms/Page";
import Boxes from "../../molecules/HowToBoxes";
import 'bootstrap/dist/css/bootstrap.min.css';
import FeaturedCard from "../../molecules/FeaturedCard";

const ThirtySix = () => {
    return (
        <Page layout="public">
            <section name="main">
                {/* Timeline Section */}
                <div className="container mt-5">
                    <div className="jumbotron text-center bg-light">
                        <h2 id={"start"}>Explore the S-Carb Method's 36-Hour Fast!</h2>
                        <p>The thought of fasting for 36 hours might seem daunting, but with the right guidance and understanding of the process, it's entirely achievable. And guess what? It isn't as hard as you might think.</p>
                        <hr className="my-4" />
                        <p>Below, you'll find an hour-by-hour breakdown of what to expect. Empower yourself with knowledge, and you'll find that the journey is both enlightening and transformative.</p>
                    </div>
                    <div className="timeline">

                        <div className="timeline-event">
                            <div className="card border-info mb-3">
                                <div className="card-header">Hour 0 (7:00 PM, Sunday)</div>
                                <div className="card-body">
                                    <p className="card-text">You officially begin your fast. You've already eaten dinner by this time and your body is still running on the energy from that last meal. You might not feel any different yet, but your body will be switching energy sources soon.</p>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-event">
                            <div className="card border-info mb-3">
                                <div className="card-header">Hour 4-6 (11:00 PM - 1:00 AM, Sunday to Monday)</div>
                                <div className="card-body">
                                    <p className="card-text">As you sleep, initial feelings of hunger might start. These are usually mild and can be managed with water or herbal tea if you happen to wake up. However, many don't even realize these sensations due to being asleep.</p>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-event">
                            <div className="card border-info mb-3">
                                <div className="card-header">Hour 12 (7:00 AM, Monday)</div>
                                <div className="card-body">
                                    <p className="card-text">You wake up. A significant change occurs. Your ability to focus sharpens. However, with depleting glucose levels, your body starts craving carbs and sugars, leading to the familiar stomach growls. Take a about two handfuls of mixed raw nuts (or two boiled eggs as a small breakfast even) to curb these hunger pangs. Maybe some coffee to drink it down with...</p>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-event">
                            <div className="card border-info mb-3">
                                <div className="card-header">Hour 15 (10:00 AM, Monday)</div>
                                <div className="card-body">
                                    <p className="card-text">As your glucose levels decline, your body initiates the transition into ketosis. During this process, the liver converts fat into ketones, which serve as an alternative energy source. On average, the liver can produce approximately 150 grams of ketones per day. As a point of reference, there are approximately 453 grams in one pound. Additionally, the body has the capacity to store about 1 pound of fat from sugars and carbs alone per day.</p>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-event">
                            <div className="card border-info mb-3">
                                <div className="card-header">Hour 18-24 (1:00 PM - 7:00 PM, Monday)</div>
                                <div className="card-body">
                                    <p className="card-text">Hunger pangs might intensify, and you might experience moments of mood swings or irritability (you're hangry!). At this point it's essential to recognize that more than anything, this stage is a mental battle. While your body might signal hunger, it's often the mind that needs the most reassurance that we aren't going to starve to death. Eating a snack can help with the physical sensations, but staying mentally strong, reminding yourself of the benefits, and focusing on the end goal can truly help you push through this challenging phase.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-event">
                            <div className="card border-info mb-3">
                                <div className="card-header">Hour 28 (11:00 PM, Monday)</div>
                                <div className="card-body">
                                    <p className="card-text">Hunger starts to wane, but you're probably asleep, and you don't notice. Many report a deeper and more rested sleep.</p>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-event">
                            <div className="card border-info mb-3">
                                <div className="card-header">Hour 32 (3:00 AM, Monday to Tuesday)</div>
                                <div className="card-body">
                                    <p className="card-text">Still asleep, but when you awake, you will notice feeling physically lighter and even more clear-headed from this point on and there might even be an energy surge upon waking. These heightened cognitive functions will persist well into the afternoon even after eating.</p>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-event">
                            <div className="card border-info mb-3">
                                <div className="card-header">Hour 36 (7:00 AM, Tuesday)</div>
                                <div className="card-body">
                                    <p className="card-text">As you approach the end of the fast and wake up, there's a sense of accomplishment. You probably don't even feel hungry anymore, and your physical appetite will probably be less. Mentally however, you will be voracious and as such it's crucial to reintroduce food gently to avoid overloading your system.</p>
                                </div>
                            </div>
                        </div>

                        <div className="card border-info mb-3">
                            <div className="card-body">
                                <p className="card-text">Note: Everyone's fasting experience can vary. Always listen to your body and consult with a healthcare professional if considering extended fasts.</p>
                            </div>
                        </div>

                    </div>
                    <div className="jumbotron text-center bg-light">
                        <h2>You've Explored Every Hour of the 36-Hour Fast!</h2>
                        <p>This isn't just a diet; it's a transformative experience for both the mind and body. Many embark on this journey and find a renewed sense of vitality and clarity.</p>
                        <hr className="my-4" />
                        <p>Ready to start your own fasting journey? Remember, it's essential to listen to your body and consult with healthcare professionals. Embrace the change, and let the S-Carb Diet guide you to a healthier you!</p>
                    </div>
                </div>
                <FeaturedCard keyword="ht-journey" limit="4"/>
                <Boxes />
            </section>
        </Page>
    );
};

export default ThirtySix;
