import React from 'react';
import { Outlet } from 'react-router-dom';
import Page from "../../organisms/Page";
import Boxes from "../../molecules/HowToBoxes"
import FeaturedCard from "../../molecules/FeaturedCard";
const Info = () => {
    return (
        <Page layout="public">
            <section name="main">
                {/* Cards Section */}

                <div className="container mt-5">

                    <div className="text-center mb-5">
                        <h1 id={"start"}>🍴 Welcome to The Shift Carb Diet! 🍴</h1>
                        <p className="lead">  Welcome to The Shift Carb Diet, often referred to as the 'S-Carb' method. <br/>Embark on a transformative journey to achieve weight management and health optimization.</p>
                    </div>

                    <div className="bg-light p-4 rounded mb-4">
                        <h2 className="text-center">🌟 The Shift Carb Diet Principle 🌟</h2>
                        <p>Switch between (fasting and) Carbs! But not just any carbs. The Shift Carb Diet stands out with its distinctive blend of intermittent fasting, extended fasting, and strategic carb intake. It's a two-phase plan that harnesses the power of ketosis without sticking strictly to a Keto diet. Instead, it incorporates carb-enriched diets on non-fasting days.</p>
                    </div>

                    <h3 className="text-center mb-4">📅 Your Shift Carb Diet Journey in Phases:</h3>

                    <div className="row">
                        <div className="col-md col-md-12 col-lg-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Phase 1: The 16/8 Starter</h4>
                                    <ul>
                                        <li>🕚 Eat: Within an 8-hour window (11 am to 7 pm).</li>
                                        <li>🍽️ Calories: Aim for ~2000 daily, focusing on a balanced ratio. Target Nutrients: 25% protein, 35% fats, 35% carbs, 5% sugars, and ensure you consume more than 30 grams of fiber.</li>
                                        <li>💪 Exercise: Integrate resistance training to enhance muscle and metabolism.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md col-md-12 col-lg-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Phase 2: The 36-Hour Deep Dive</h4>
                                    <ul>
                                        <li>🕖 Fast: Start at 7 pm and conclude at 7 am, two days later.</li>
                                        <li>🧀 Snack Smart: 'Cheat' with no-carb, no-sugar delights if needed.</li>
                                        <li>🍽️ Calories: Limit to around 500 calories <b>daily</b> during this phase. Keep in the ketogenic stage through your entire fast.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md col-md-12 col-lg-4 mb-1">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Maintenance Mode: Sustain Gains</h4>
                                    <ul>
                                        <li>🏋️ Repeat: Use Phase 1 as a template for your maintenance mode's everyday methodology.</li>
                                        <li>🌟 Goal: Maintain your desired weight while indulging in a balanced diet.</li>
                                        <li>🏋️ Exercise: Prioritize strength and resistance training. Muscle maintenance is the core exercise goal while in maintenance mode.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-light p-4 rounded mt-4">
                        <h2 className="text-center">💡 S-Carb Insights:</h2>
                        <ul>
                            <li>🚀 Begin with Phase 1: Dedicate a month to get accustomed and build a sustainable routine.</li>
                            <li>💧 Stay Hydrated: Especially during fasting. Listen to your body!</li>
                            <li>📊 Track Your Progress: Use wearables like the Apple Watch to monitor your daily movement and calorie burn.</li>
                            <li>🤝 Health First: Always consult with a healthcare professional before embarking on this journey.</li>
                        </ul>
                    </div>

                    <div class="text-center mt-5">
                        <h3>🌐 Join the Shift Carb Diet Community Today! 🌐</h3>
                        <p>Dive into a lifestyle that blends the effectiveness of carb cycling and fasting. Healthier, happier living awaits!</p>
                    </div>

                </div>
                <FeaturedCard keyword="ht-about" limit="4"/>
                <Boxes/>
            </section>
        </Page>
    );
};

export default Info;