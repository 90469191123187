import React from 'react';
import Page from "../../organisms/Page";
import Boxes from "../../molecules/WhatToEatBoxes"
import FeaturedCard from "../../molecules/FeaturedCard";
const Eats = () => {
    return (
        <Page layout="public">
            <section name="main">
                <div className="container mt-5">

                    <div className="text-center mb-5">
                        <h1>🍲 Extended Fasting Snacks 🍲</h1>
                        <p className="lead">What can you eat while you're on an S-Carb Fast?</p>
                    </div>

                    <div className="bg-light p-4 rounded mb-4">
                        <h2 className="text-center">⏳ S-Carb Approved Snacking ⏳</h2>
                        <p>Fasting doesn't mean you have to deprive yourself. It's all about making smart choices. Here are some nutrient-dense, low-carb, and no-sugar snacks that'll keep you satiated and energized during your S-Carb fast:</p>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Refreshing Bites</h4>
                                    <ul>
                                        <li>🥒 Cucumber Slices with Sea Salt</li>
                                        <li>🍅 Cherry Tomatoes</li>
                                        <li>🥕 Carrot Sticks with Guacamole</li>
                                        <li>🍓 Unsweetened Coconut Yogurt with Berries</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Protein Boosters</h4>
                                    <ul>
                                        <li>🥚 Hard-Boiled Eggs with a sprinkle of Paprika</li>
                                        <li>🍗 Grilled Chicken Strips</li>
                                        <li>🐟 Smoked Salmon</li>
                                        <li>🥜 Almond Butter on Celery Sticks</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Rich & Filling</h4>
                                    <ul>
                                        <li>🥑 Avocado Halves drizzled with Olive Oil</li>
                                        <li>🧀 Cheese Cubes or Slices</li>
                                        <li>🍵 Green Tea with a slice of Lemon</li>
                                        <li>🍵 Bone Broth for a warm, comforting sip</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>



                        <FeaturedCard keyword="wte-snacks" limit="4" title={<div className="text-center mt-5">
                            <h3>🌄 Elevate Your Fasting Experience with Our Curated Snacks! 🌄</h3>
                            <p>Empower your fasting journey with snacks that nourish and satisfy without breaking your fast.</p>
                        </div>}/>

                    <div className="bg-light p-4 rounded mt-1">
                        <h2 className="text-center">🔍 Snacking Smartly on an S-Carb Fast 🔍</h2>
                        <ul>
                            <li>🍴 Portion Control: Keep track of how much you're eating, even if it's a healthy snack.</li>
                            <li>🥤 Hydration: Drink plenty of water to stay full and flush out toxins.</li>
                            <li>🌱 Freshness: Opt for fresh, whole foods over processed snacks.</li>
                            <li>📊 Monitor: Listen to your body and monitor how certain snacks affect your energy and satiety.</li>
                            <li>🕰 Timing: If possible, try to eat your snacks during your non-fasting window.</li>
                        </ul>
                    </div>
                </div>

                <Boxes/>
            </section>
        </Page>
    );
};

export default Eats;