import React from 'react';
import { Outlet } from 'react-router-dom';
import Page from "../../organisms/Page";
import Boxes from "../../molecules/HowToBoxes"
import FeaturedCard from "../../molecules/FeaturedCard";
const Info = () => {
    return (
        <Page layout="public">
            <section name="main">

                {/* Cards Section */}
                <div className="container mt-5">

                    <div className="text-center mb-5">
                        <h1 id={"start"}>🚀 Getting Started with the S-Carb Diet 🚀</h1>
                        <p className="lead">Lay a solid foundation for your transformative journey with the S-Carb Diet.</p>
                    </div>

                    <div className="bg-light p-4 rounded mb-4">
                        <h2 className="text-center">🌱 Setting the Groundwork</h2>
                        <p>Before diving in, dedicate a full month to Phase 1. This foundational phase is pivotal in training your body, acclimatizing to the eating schedules, and viewing the S-Carb Diet as a sustainable lifestyle rather than just another diet. Remember, it's crucial to consult your physician before diving deep into this dietary adventure.</p>
                    </div>

                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h3 className="card-title">Phase 1: The 16/8 Intermittent Fast</h3>
                                    <ul>
                                        <li>🍽️ Feast: From 11 am to 7 pm.</li>
                                        <li>📊 Calories: Stick to a ~2000 daily budget.</li>
                                        <li>🌞 Morning Priority: Bulk up morning meals to minimize daytime hunger.</li>
                                        <li>💪 Workout: Incorporate resistance training and weight lifting to boost muscle mass and metabolism.</li>
                                        <li>🚫 Dietary Shifts: Phase out potentially inflammatory foods.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h3 className="card-title">Phase 2: The 36-Hour No Carb, No Sugar Fast</h3>
                                    <ul>
                                        <li>⏰ Timing: Begin at 7 pm and conclude at 7 am two days later.</li>
                                        <li>🧀 Smart Snacking: If cravings strike, opt for non-carb, non-sugar delights like proteins, cheeses, and fats.</li>
                                        <li>📊 Calories: Maintain a limit of ~500 daily.</li>
                                        <li>💪 Exercise: Tailor workouts to your calorie deficit, ensuring you burn what you consume.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-md-12 mt-5">
                        <h5 className="text-center small fst-italic text-info-emphasis">example schedule</h5>
                        <h3 className="text-center">Phase 1 - 16/8 Intermittent Fast</h3>
                        <div className="row border">
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Sunday</div>
                                <ul>
                                    <li>11 am: First meal - 1400 cal</li>
                                    <li>4 pm: Second meal - 600 cal</li>
                                </ul>
                            </div>
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Monday</div>
                                <ul>
                                    <li>11 am: First meal - 500 cal</li>
                                    <li>3 pm: Second meal - 1100 cal</li>
                                    <li>4 pm: Third meal - 400 cal</li>
                                </ul>
                            </div>
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Tuesday</div>
                                <ul>
                                    <li>12 pm: First meal - 1200 cal</li>
                                    <li>4 pm: Second meal - 800 cal</li>
                                </ul>
                            </div>
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Wednesday</div>
                                <ul>
                                    <li>10 am: First meal - 400 cal</li>
                                    <li>1 pm: Second meal - 600 cal</li>
                                    <li>5 pm: Third meal - 1000 cal</li>
                                </ul>
                            </div>
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Thursday</div>
                                <ul>
                                    <li>11 am: First meal - 1400 cal</li>
                                    <li>4 pm: Second meal - 600 cal</li>
                                </ul>
                            </div>
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Friday</div>
                                <ul>
                                    <li>11:30 am: First meal - 1000 cal</li>
                                    <li>3 pm: Second meal - 600 cal</li>
                                    <li>7 pm: Third meal - 400 cal</li>
                                </ul>
                            </div>
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Saturday</div>
                                <ul>
                                    <li>10 am: First meal - 1200 cal</li>
                                    <li>4 pm: Second meal - 800 cal</li>
                                </ul>
                            </div>
                        </div>
                        <p>Stick to your 2000 goal calorie budget. It's okay to go over or under a bit. In maintenance mode timing isn't as important either. The point is to <u>actively control</u> your eating window.</p>

                    </div>
                    <div className="col-md-12 mt-5">
                        <h5 className="text-center small fst-italic text-info-emphasis">example schedule</h5>
                        <h3 className="text-center">Phase 2 - 36-Hour No Carb, No Sugar Fast</h3>
                        <div className="row border">
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Sunday</div>
                                <ul>
                                    <li>11 am: First meal - 1100 cal</li>
                                    <li>3 pm: Second meal - 600 cal</li>
                                    <li>4 pm: Third meal - 400 cal</li>
                                    <li>7 pm: Start fast</li>
                                </ul>
                            </div>
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Monday - Fasting All Day</div>
                                <ul>
                                    <li>11 am: Snack - 300 cal</li>
                                    <li>4 pm: Snack - 200 cal</li>
                                </ul>
                            </div>
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Tuesday</div>
                                <ul>
                                    <li>7 am: Complete fast</li>
                                    <li>10 am: First meal - 500 cal</li>
                                    <li>3 pm: Second meal - 1100 cal</li>
                                    <li>4 pm: Third meal - 400 cal</li>
                                    <li>7 pm: Start fast</li>
                                </ul>
                            </div>
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Wednesday - Fasting All Day</div>
                                <ul>
                                    <li>11 am: Snack - 250 cal</li>
                                    <li>4 pm: Snack - 250 cal</li>
                                </ul>
                            </div>
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Thursday</div>
                                <ul>
                                    <li>7 am: Complete fast</li>
                                    <li>12 pm: First meal - 800 cal</li>
                                    <li>4 pm: Second meal - 800 cal</li>
                                    <li>6 pm: Third meal/snack - 400 cal</li>
                                    <li>7 pm: Start fast</li>
                                </ul>
                            </div>
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Friday - Fasting All Day</div>
                                <ul>
                                    <li>11 am: Snack - 400 cal</li>
                                    <li>7 pm: Snack - 100 cal</li>
                                </ul>
                            </div>
                            <div className="col border">
                                <div className="text-center small text-decoration-underline">Saturday</div>
                                <ul>
                                    <li>7 am: Complete fast</li>
                                    <li>10 am: First meal - 600 cal</li>
                                    <li>4 pm: Second meal - 1200 cal</li>
                                    <li>7 pm: Snack - 400 cal</li>
                                </ul>
                            </div>
                        </div>
                        <p>During fasting days, consider strategic snack breaks at 11 am and 4 pm with a total calorie intake of ~500.<br/>
                            During eating days, stick to your 2000 goal calorie budget. It's okay to go over or under a bit.<br/>
                            Timing window is important during phase 2 fasting, because it affects your ketosis burn, so keep it in strict control.</p>
                    </div>



                    <div className="text-center mt-5">
                        <h2>🌟 Embrace the Shift Carb Diet Lifestyle Today! 🌟</h2>
                        <p>Set yourself on a path to healthier living, optimized weight management, and a happier you! Oh yea, it's free too.</p>
                    </div>

                        <FeaturedCard keyword="ht-getting-started" limit="4"/>
                </div>

                <Boxes/>
            </section>
        </Page>
    );
};

export default Info;