import React from 'react';
import Page from "../../organisms/Page";
import Boxes from "../../molecules/ExerciseBoxes"
import FeaturedCard from "../../molecules/FeaturedCard";
const Info = () => {
    return (
        <Page layout="public">
            <section name="main">
                {/* Cards Section */}
                <div className="bg-light p-4 rounded mt-4">
                    <h2 className="text-center">💪 The Flexible S-Carb Exercise Regime 💪</h2>
                    <p>Exercise is a vital component of the S-Carb Diet, and we've designed a flexible regime to accommodate various preferences and fitness levels. Our approach combines a variety of exercises to ensure a holistic fitness experience that aligns with your weight management and health optimization goals.</p>

                    <h3 className="text-center mb-4">🏃 Your Exercise Journey in Phases:</h3>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Phase 1: The 16/8 Starter</h4>
                                    <p>In Phase 1, you'll engage in exercise within your chosen 8-hour eating window (typically from 11 am to 7 pm). This phase emphasizes:</p>
                                    <ul>
                                        <li>🏋️ Resistance Training: Incorporate exercises like squats, push-ups, and pull-ups to build muscle and boost metabolism.</li>
                                        <li>🚴 Cardiovascular Workouts: Include cycling sessions for a balanced fitness routine.</li>
                                    </ul>
                                    <p>The goal here is to lay the foundation for your fitness journey and develop a sustainable exercise routine.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Phase 2: The 36-Hour Deep Dive</h4>
                                    <p>During Phase 2, which involves the 36-hour fast, exercise plays a critical role in optimizing fat burning. Here's what to focus on:</p>
                                    <ul>
                                        <li>🏃 Active Movement: Maintain your daily calorie expenditure with light activities such as walking or yoga.</li>
                                        <li>🧘 Enhance Flexibility: Incorporate stretching exercises to relieve muscle tension and prepare your body for rest.</li>
                                    </ul>
                                    <p>While the exercise intensity may be lower during this phase, it's essential to support your body's adaptation to fasting.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Maintenance Mode: Sustain Your Gains</h4>
                                    <p>Once you've reached your goal weight, Maintenance Mode ensures you maintain your achievements through:</p>
                                    <ul>
                                        <li>🏋️ Strength Training: Prioritize strength and resistance training to build and maintain muscle mass.</li>
                                        <li>🏃‍♂️ Active Lifestyle: Continue daily movement to match your calorie intake and expenditure.</li>
                                    </ul>
                                    <p>This phase focuses on preserving your desired weight while enjoying a balanced diet.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Our flexible exercise regime allows you to:</p>
                    <ul>
                        <li>🕒 Customize Your Schedule: Choose the best time for workouts that fits your lifestyle.</li>
                        <li>🏋️ Diversify Your Workouts: Explore a range of exercises, from resistance training to cardio and flexibility workouts.</li>
                        <li>📈 Progress Gradually: Start with what's comfortable and challenge yourself over time as you progress through the phases.</li>
                        <li>💡 Personalize Your Approach: Listen to your body and make adjustments based on your energy levels and preferences.</li>
                    </ul>
                    <p>Remember, consistency is key to success. Find a routine that works for you and keeps you motivated on your S-Carb journey to achieve optimal health and weight management.</p>
                </div>
                <FeaturedCard keyword="ex-about" limit="4"/>
                <Boxes/>
            </section>
        </Page>
    );
};

export default Info;