import React from 'react';
import Page from "../../organisms/Page";
import Boxes from "../../molecules/WhatToEatBoxes"
import FeaturedCard from "../../molecules/FeaturedCard";
const Eats = () => {
    return (
        <Page layout="public">
            <section name="main">
                <div className="container mt-5">

                    <div className="text-center mb-5">
                        <h1>🍏 What to Eat on the S-Carb Diet 🍏</h1>
                        <p className="lead">Discover the foods and snacks that fuel your S-Carb journey.</p>
                    </div>

                    <div className="bg-light p-4 rounded mb-4">
                        <h2 className="text-center">🍴 Phase 1: The 16/8 Intermittent Fast 🍴</h2>
                        <p>During this phase, it's all about nutrient density within a ~2000 calorie budget. Here are some delicious and healthy meal options to kickstart your day:</p>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Breakfast Delights</h4>
                                    <ul>
                                        <li>🥤 Protein-Packed Smoothie</li>
                                        <li>🍳 Spinach & Mushroom Omelette</li>
                                        <li>🥖 Whole Grain Avocado Toast</li>
                                        <li>🥣 Greek Yogurt with Berries and Nuts</li>
                                        <li>🌯 Turkey and Egg Breakfast Burrito</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Lunch & Dinner Favorites</h4>
                                    <ul>
                                        <li>🥗 Grilled Chicken Salad</li>
                                        <li>🍲 Quinoa Veggie Bowl</li>
                                        <li>🌮 Fish Tacos</li>
                                        <li>🍝 Whole Wheat Pasta Primavera</li>
                                        <li>🥘 Stir-Fried Tofu with Mixed Vegetables</li>
                                    </ul>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Healthy Snacks</h4>
                                    <ul>
                                        <li>☕ Coffee</li>
                                        <li>🧀 Cheese Cubes</li>
                                        <li>🌰 Handful of Nuts</li>
                                        <li>🥒 Hummus & Veggies</li>
                                        <li>🍧 Yogurt Parfait</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-light p-4 rounded mt-4">
                        <h2 className="text-center">🍖 Phase 2: The 36-Hour No Carb, No Sugar Fast 🍖</h2>
                        <p>While your body is deep into the fasting phase, here are keto-inspired snacks to keep your energy levels optimal:</p>
                        <div className="row">
                            <div className="col-md-4">
                                <ul>
                                    <li>☕ Black Coffee</li>
                                    <li>🥑 Avocado Slices</li>
                                    <li>🫒 Olives</li>
                                    <li>🧀 Cheese Varieties</li>
                                    <li>🍖 Cold Cut Meats</li>
                                    <li>🥒 Cucumber Slices</li>
                                    <li>🍄 Marinated Mushrooms</li>
                                    <li>🥦 Steamed Broccoli Florets</li>
                                    <li>🥄 Coconut Cream</li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul>
                                    <li>🌰 Assorted Nuts</li>
                                    <li>🥜 Nut Butters</li>
                                    <li>🥚 Hard-Boiled Eggs</li>
                                    <li>🍗 Grilled Chicken Strips</li>
                                    <li>🐟 Smoked Salmon</li>
                                    <li>🥗 Leafy Green Salad</li>
                                    <li>🌶️ Bell Pepper Strips</li>
                                    <li>🍤 Shrimp Cocktail (no sauce)</li>
                                    <li>🥥 Chia Seed Pudding</li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul>
                                    <li>🍫 Keto Fat Bombs</li>
                                    <li>🥥 Coconut Almond Fat Bombs</li>
                                    <li>🧈 Butter Pecan Fat Bombs</li>
                                    <li>🥑 Avocado Cocoa Fat Bombs</li>
                                    <li>🍋 Lemon Cheesecake Fat Bombs</li>
                                    <li>🍓 Strawberry Cream Fat Bombs</li>
                                    <li>🍊 Orange Dark Chocolate Fat Bombs</li>
                                    <li>🌰 Macadamia Nut Fat Bombs</li>
                                    <li>🥓 Bacon and Egg Fat Bombs</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <FeaturedCard keyword="wte-healthy-eating" limit="4" title={<div className="text-center mt-5">
                        <h3>🌄 Elevate Your Fasting Experience with Our Curated Snacks! 🌄</h3>
                        <p>Empower your fasting journey with snacks that nourish and satisfy without breaking your fast.</p>
                    </div>}/>

                    <div class="bg-light p-4 rounded mt-4">
                        <h2 class="text-center">🔍 General Guidelines & Tips 🔍</h2>
                        <ul>
                            <li>🍇 Prioritize natural sugars and unprocessed foods.</li>
                            <li>🥤 Drink your calories wisely.</li>
                            <li>🌈 Aim for a balanced diet with targeted macronutrient ratios.</li>
                            <li>💧 Stay hydrated with water or herbal teas.</li>
                            <li>💊 Consider a multivitamin for added nutritional support.</li>
                        </ul>
                    </div>

                    <div class="text-center mt-5">
                        <h3>🍀 Here's to Healthier Living with the S-Carb Diet! 🍀</h3>
                        <p>Stay informed, stay motivated, and enjoy your transformative journey!</p>
                    </div>

                </div>

                <Boxes/>
            </section>
        </Page>
    );
};

export default Eats;