import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const customBoxStyleBase = {
    position: 'relative'
};

let customBoxStyle = {
    ...customBoxStyleBase,
};

const Boxes = () => {
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, [location.pathname]);

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md mb-3" style={customBoxStyle}>
                    <Link to="/what-to-eat/healthy-foods" className="text-decoration-none">
                        <div className="card">
                            <img src="/assets/img/healthy-eating.png" className="card-img-top" alt="Eating Healthy"/>
                            <div className="card-body">
                                <h5 className="card-title">Healthy Eating Examples</h5>
                                <p className="card-text">Every day examples of how to eat healthy.</p>
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="col-md mb-3" style={customBoxStyle}>
                    <Link to="/what-to-eat/fasting-snacks" className="text-decoration-none">
                        <div className="card">
                            <img src="/assets/img/fasting-snacks.png" className="card-img-top" alt="Snacks"/>
                            <div className="card-body">
                                <h5 className="card-title">Extended Fasting Snacks</h5>
                                <p className="card-text">What can you eat while your on a S-Carb Fast?</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md mb-3" style={customBoxStyle}>
                    <Link to="/what-to-eat/fat-bomb-recipes" className="text-decoration-none">
                        <div className="card">
                            <img src="/assets/img/fatbomb-recipes.png" className="card-img-top"
                                 alt="Fat bomb recipes"/>
                            <div className="card-body">
                                <h5 className="card-title">Fat Bombs</h5>
                                <p className="card-text">Recipes to make and sources to buy pre-made fat bombs.</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md mb-3" style={customBoxStyle}>
                    <Link to="/what-to-eat/inflammatory-foods"  className="text-decoration-none">
                        <div className="card">
                            <img src="/assets/img/inflammatory-foods.png" className="card-img-top"
                                 alt="Inflammatory foods"/>
                            <div className="card-body">
                                <h5 className="card-title">Inflammatory foods</h5>
                                <p className="card-text">Steer clear of these foods for optimal health and vitality.</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Boxes;