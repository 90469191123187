import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const customBoxStyleBase = {

    position: 'relative'
};

let customBoxStyle = {
    ...customBoxStyleBase,
};

const Boxes = () => {
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, [location.pathname]);

    return (
        <div className="container mt-5">
            <div className="row">
                {/* Introduction Card */}
                <div className="col-md mb-3" style={customBoxStyle}>
                    <Link to="/exercise/about" className="text-decoration-none">
                        <div className="card">
                            <img src="/assets/img/exercise-guide.png" className="card-img-top" alt="Eating Healthy"/>
                            <div className="card-body">
                                <h5 className="card-title">Exercise Guide</h5>
                                <p className="card-text">Essential activities that align with the S-Carb Philosophy</p>
                            </div>
                        </div>
                    </Link>
                </div>

                {/* Getting Started Card */}
                <div className="col-md mb-3" style={customBoxStyle}>
                    <Link to="/exercise/morning" className="text-decoration-none">
                        <div className="card">
                            <img src="/assets/img/exercise-morning.png" className="card-img-top" alt="Snacks"/>
                            <div className="card-body">
                                <h5 className="card-title">Morning Rituals</h5>
                                <p className="card-text">Start your day with a burst and set the pace for the day.</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md mb-3" style={customBoxStyle}>
                    <Link to="/exercise/daytime" className="text-decoration-none">
                        <div className="card">
                            <img src="/assets/img/exercise-daytime.png" className="card-img-top"
                                 alt="Fat bomb recipes"/>
                            <div className="card-body">
                                <h5 className="card-title">Daytime Workouts</h5>
                                <p className="card-text">Heat up your lunch with some Strength or High Intensity training</p>
                            </div>
                        </div>
                    </Link>
                </div>
                {/* Phase 1 Card */}
                <div className="col-md mb-3" style={customBoxStyle}>
                    <Link to="/exercise/evening" className="text-decoration-none">
                        <div className="card">
                            <img src="/assets/img/exercise-evening.png" className="card-img-top"
                                 alt="Inflammatory foods"/>
                            <div className="card-body">
                                <h5 className="card-title">Evening Wind Down</h5>
                                <p className="card-text">Time to bring it in and start to relax for the day.</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Boxes;