import React, { useState } from 'react';

function ExpandableText({ text, elementType = 'h5', styleOverride }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleMouseEnter = () => {
        setIsExpanded(true);
    };

    const handleMouseLeave = () => {
        setIsExpanded(false);
    };

    const normalStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        transition: 'maxWidth 0.3s ease-in-out',
        ...styleOverride
    };

    const expandedStyle = {
        whiteSpace: 'normal',
        overflow: 'visible',
        textOverflow: 'clip',
        maxWidth: 'none',
        transition: 'maxWidth 0.3s ease-in-out',
        ...styleOverride
    };

    const ElementType = elementType;

    return (
        <ElementType
            style={isExpanded ? expandedStyle : normalStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {text}
        </ElementType>
    );
}

export default ExpandableText;
