export const fetchDataFiles = async () => {
    const response = await fetch('/fileList.json');
    const fileNames = await response.json();
    const importedData = await Promise.all(
        fileNames.map(fileName =>
            fetch(`/data/${fileName}`)
                .then(response => response.json())
        )
    );
    // Flatten the array
    return [].concat.apply([], importedData);
};