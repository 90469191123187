import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    // Define the number of pages to display in each group
    const pagesPerGroup = 5;

    // Calculate the total number of groups
    const totalGroups = Math.ceil(totalPages / pagesPerGroup);

    // Calculate the current group
    const currentGroup = Math.ceil(currentPage / pagesPerGroup);

    // Calculate the start and end page numbers for the current group
    const startPage = (currentGroup - 1) * pagesPerGroup + 1;
    const endPage = Math.min(currentGroup * pagesPerGroup, totalPages);

    // Create an array of page numbers to display
    const pagesToShow = [];
    for (let i = startPage; i <= endPage; i++) {
        pagesToShow.push(i);
    }

    return (
        <nav>
            <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                    <button className="page-link" onClick={() => onPageChange(currentPage - 1)}>
                        Previous
                    </button>
                </li>
                {currentGroup > 1 && (
                    <li className="page-item">
                        <button className="page-link" onClick={() => onPageChange(startPage - 1)}>
                            ...
                        </button>
                    </li>
                )}
                {pagesToShow.map((page) => (
                    <li key={page} className={`page-item ${currentPage === page && 'active'}`}>
                        <button className="page-link" onClick={() => onPageChange(page)}>
                            {page}
                        </button>
                    </li>
                ))}
                {currentGroup < totalGroups && (
                    <li className="page-item">
                        <button className="page-link" onClick={() => onPageChange(endPage + 1)}>
                            ...
                        </button>
                    </li>
                )}
                <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                    <button className="page-link" onClick={() => onPageChange(currentPage + 1)}>
                        Next
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
