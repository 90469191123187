import React from 'react';
import Page from "../../organisms/Page";
import Boxes from "../../molecules/ExerciseBoxes"
import FeaturedCard from "../../molecules/FeaturedCard";
const Info = () => {
    return (
        <Page layout="public">
            <section name="main">
                <div className="bg-light p-4 rounded mt-4">
                    <h2 className="text-center">💪 Daytime Workouts: Heat Up Your Lunch with Strength and HIIT 💪</h2>
                    <p>Daytime workouts are a powerful way to boost your metabolism, increase muscle mass, and rev up your energy levels. With the S-Carb Diet, we encourage you to dedicate time to strength training and high-intensity interval training (HIIT) during the day to achieve your fitness goals.</p>

                    <h3 className="text-center mb-4">🌞 Build Muscle, Burn Calories and Boost Energy</h3>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">🏋️‍♂️ Strength Training</h4>
                                    <p><strong>Duration:</strong> 30-40 minutes</p>
                                    <p><strong>Benefits:</strong> Builds muscle mass, boosts metabolism, and strengthens the entire body.</p>
                                    <p><strong>Routine:</strong> Focus on compound movements like squats, deadlifts, push-ups, and pull-ups. Incorporate weights as per your comfort level.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">🔥 High-Intensity Interval Training (HIIT)</h4>
                                    <p><strong>Duration:</strong> 15-20 minutes</p>
                                    <p><strong>Benefits:</strong> Burns a lot of calories in a short time, improves cardiovascular health.</p>
                                    <p><strong>Routine:</strong> Alternate between 30 seconds of all-out effort and 30 seconds of rest. Exercises can include burpees, jumping jacks, and mountain climbers.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>These daytime workouts are designed to:</p>
                    <ul>
                        <li>💪 Build Muscle Mass: Strength training helps you build and maintain muscle, which contributes to a faster metabolism.</li>
                        <li>🔥 Burn Calories: HIIT is an efficient way to burn calories, making it an excellent choice for fat loss and cardiovascular health.</li>
                        <li>🏋️‍♂️ Strengthen Your Body: Both strength training and HIIT contribute to overall body strength, improving your fitness and daily activities.</li>
                        <li>🌞 Boost Energy: Daytime workouts can provide an energy boost that helps you stay active and focused throughout the day.</li>
                    </ul>
                    <p><span className="fw-bold"><em>Soulful Bonus</em></span>: Ah, the daytime, where life's stresses often accumulate like clouds on a stormy afternoon. But fear not, for you have been given a sacred tool—your body, the temple of your soul. Through these workouts, you're not just building muscle or burning calories; you're alchemizing your stress, transmuting cortisol into droplets of sweat that fall away, leaving you lighter, freer, more yourself. Each bead of sweat is a negative thought, a worry, a fear—released, gone, transformed! Consider this not just a workout, but a ritual of purification and renewal. As you move, imagine your stresses vaporizing, leaving your body, absorbed by the Earth. You're not just working out; you're working within, doing the inner alchemy of the soul. So go ahead—sweat out the old, breathe in the new, and emerge from each session reborn!</p>
                    <p>Remember to adapt these workouts to your fitness level and preferences. Consistency in daytime workouts can lead to impressive results, whether you're aiming to gain strength, lose weight, or simply stay fit.</p>
                </div>
                <FeaturedCard keyword="ex-daytime" limit="4"/>
                <Boxes/>
            </section>
        </Page>
    );
};

export default Info;