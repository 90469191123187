import React from 'react';
import Page from "../organisms/Page";
const Info = () => {
    return (
        <Page layout="public">
            <section name="main">
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <h2 className="text-center mb-4">Support Our Mission</h2>
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Help Us Spread the Word!</h4>
                                    <p className="card-text">
                                        Our goal is to help people achieve their fitness and health aspirations. By sharing our platform with your friends, family, and peers, you're not just promoting us – you're sharing a resource that might change someone's life.
                                    </p>
                                    <p className="card-text">
                                        Word of mouth has always been an invaluable tool for genuine and organic growth. By talking about our platform, you help us reach more individuals who could benefit from our guidance.
                                    </p>
                                    <hr />
                                    <h4 className="card-title">Affiliate-Backed Products</h4>
                                    <p className="card-text">
                                        Apart from sharing, another way to support us is by purchasing the products displayed on our site. These products are affiliate-backed, meaning a small portion of the proceeds goes into maintaining and improving this platform, ensuring we can continue offering our services free of charge.
                                    </p>
                                    <p className="card-text">
                                        Rest assured, we only display products that align with our ethos and that we believe can genuinely benefit our users.
                                    </p>

                                    <hr />
                                    <h4 className="card-title">Local Workshops: Take The Lead!</h4>
                                    <p className="card-text">
                                        Have you ever considered starting a local workshop or group to discuss diet, fitness, and health strategies? It's a fantastic way to connect with like-minded individuals, share experiences, and support each other's journeys.
                                    </p>
                                    <p className="card-text">
                                        If you're passionate and want to take the initiative to start a local workshop, we're here to support and guide you. Reach out, and let's discuss how we can collaborate and foster a vibrant community together.
                                    </p>
                                    <a href="mailto:&#x63;&#x6F;&#x6E;&#x74;&#x61;&#x63;&#x74;&#x2D;&#x75;&#x73;&#x40;&#x73;&#x2D;&#x63;&#x61;&#x72;&#x62;&#x2E;&#x63;&#x6F;m?subject=Interest in Starting a Local Workshop" className="btn btn-info">Contact Us to Discuss</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Page>
    );
};

export default Info;