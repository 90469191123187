import Page from "../organisms/Page";

export default () =>{

	return (
		<Page layout="public">
			<h1>Title</h1>
			<section name="main" className={'mt-5 pt-5 mb-5'}>
				<h1 >Oops! Looks like you've fasted from the right path...</h1>
				But don't worry, just like Shift Carb, we've got a way to guide you back.
				<br/><a href={`/`}>[Return to Home]</a>
			</section>
		</Page>
)};