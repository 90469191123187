import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import NotFoundPage from '../components/pages/404';
import { useLocation } from 'react-router-dom';

// Inside your component

export default function MarkdownLoader() {
    const [markdownContent, setMarkdownContent] = useState('');
    const [notFound, setNotFound] = useState(false);
    const location = useLocation();
    const fullPath = `${location.pathname}.md`;
    useEffect(() => {
        import(`/public/markdown${fullPath}`)
            .then(module => {
                fetch(module.default)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Markdown file not found');
                        }
                        return response.text();
                    })
                    .then(text => setMarkdownContent(text))
                    .catch(err => {
                        if (err.message === 'Markdown file not found') {
                            setNotFound(true);
                        } else {
                            console.error("Failed to load markdown file:", err);
                        }
                    });
            })
            .catch(err => {
                console.error("Failed to import markdown file:", err);
                setNotFound(true);
            });
    }, [fullPath]);

    if (notFound) {
        return <NotFoundPage />;
    }
    return <><div className={'mt-5 pt-2'}></div><ReactMarkdown>{markdownContent}</ReactMarkdown></>;
}
