import React, {useEffect, useState} from 'react';
import ReactImageMagnify from "react-image-magnify";

const ProductModal = ({ product, onClose }) => {
    const [selectedImage, setSelectedImage] = useState(product.images && product.images.length ? product.images[0] : null);
    const logoStyles = {
        backgroundColor: 'grey',
        opacity: 0.3
    };

    // Add an event listener to handle "Escape" key press
    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.keyCode === 27) {
                onClose(); // Call onClose when "Escape" key is pressed
            }
        };

        document.addEventListener('keydown', handleEscapeKey);

        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [onClose]);

    return (
        <div className="modal show d-block col-md-12" tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <div>
                            <h5 className="modal-title">{product.name}</h5>
                            {product.brand && <h6 className="card-subtitle mb-2 text-muted">by {product.brand}</h6>}
                        </div>
                        <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body row">
                        {product.targetPixel &&
                            <img
                                src={product.targetPixel}
                                width="1"
                                height="1"
                                border="0"
                                alt=""
                                style={{border: "none !important", margin: "0px !important"}}
                            />
                        }
                            {product.images && product.images.length > 1 ? (
                                <div className="col-md-2">
                                    {/* Thumbnail images */}
                                    {product.images.map((img, index) => (
                                        <img
                                            key={index}
                                            src={img}
                                            alt={`Thumbnail ${index}`}
                                            className="img-thumbnail mb-2"
                                            onClick={() => setSelectedImage(img)}
                                        />
                                    ))}
                                </div>
                            ) : null}
                            <div className={`col-md-${product.images && product.images.length > 1 ? '4' : '6'} d-flex justify-content-center align-items-center`}>
                                {/* Display main image */}
                                {selectedImage ? (
                                    <ReactImageMagnify {...{
                                        smallImage: {
                                            alt: product.name,
                                            isFluidWidth: true,
                                            src: selectedImage,
                                        },
                                        largeImage: {
                                            src: selectedImage,
                                            width: 1200,
                                            height: 1800
                                        },
                                        enlargedImagePosition: 'over',
                                        style: { width: '100%', height: '100%' } // This will make ReactImageMagnify fill the container
                                    }} className={product.images && product.images.length > 1 && 'w-100'} />
                                ) : (
                                    <img src="assets/img/shiftcarblogo.png" alt={product.name} style={logoStyles} className="img-fluid" />
                                )}
                            </div>

                        <div className="col-md-6">
                            {/* Product information */}
                            {product.description ? (
                                <div>
                                    <strong>Description: </strong>
                                    <span dangerouslySetInnerHTML={{ __html: product.description }}></span>
                                </div>
                            ) : null}
                            {product.note ? (
                                <div className={"mt-5"}>
                                    <strong>Note: </strong>
                                    <span dangerouslySetInnerHTML={{ __html: product.note }}></span>
                                </div>
                            ) : null}
                        </div>

                    </div>
                    <div className="modal-footer">
                        <div className={"row"}>
                            <div className={"md-col-6"}>
                                {product.price && <h3 className="font-weight-bold text-end">{product.price}</h3>}
                            </div>
                            <div className={"md-col-6"}>
                                <a href={product.url} target="_blank" className="btn btn-block w-100 mb-2" style={{ backgroundColor: '#cfe2ff' }}>Buy Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductModal;
