import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Home from '../components/pages/Home';
import Info from '../components/pages/Info';
import MarkdownLoader from '../utility/MarkdownLoader';
import WhatToEat from "../components/pages/WhatToEat/WhatToEat";
import WhatToEatHealthy from "../components/pages/WhatToEat/WhatToEat-HealthyEating";
import WhatToEatSnacks from "../components/pages/WhatToEat/WhatToEat-Snacks";
import WhatToEatRecipes from "../components/pages/WhatToEat/WhatToEat-Recipes";
import WhatToEatInflammatory from "../components/pages/WhatToEat/WhatToEat-Inflammatory";
import Exercise from "../components/pages/Exercise/Exercise";
import ExerciseAbout from "../components/pages/Exercise/Exercise-About";
import ExerciseMorning from "../components/pages/Exercise/Exercise-Morning";
import ExerciseDaytime from "../components/pages/Exercise/Exercise-Daytime";
import ExerciseEvening from "../components/pages/Exercise/Exercise-Evening";
import HowTo from "../components/pages/HowTo/HowTo";
import HowToAbout from "../components/pages/HowTo/HowTo-About";
import HowTo36 from "../components/pages/HowTo/HowTo-36Hours";
import HowToStart from "../components/pages/HowTo/HowTo-Start";
import HowToNotes from "../components/pages/HowTo/HowTo-Notes";
import NotFound from "../components/pages/404";
import Products from "../components/pages/Products"
import Support from "../components/pages/Support"

// Create a context to manage the 404 state
export const NotFoundContext = React.createContext();

const RedirectToFirstMD = () => {
	const navigate = useNavigate();
	navigate('/info/pizza');
	return null;
};

const NotFoundProvider = ({ children, initialIs404 }) => {
    const [is404, setIs404] = React.useState(initialIs404);
	// Set the 404 state when the NotFound component is mounted
	useEffect(() => {
		setIs404(initialIs404);
	}, []);

	return (
		<NotFoundContext.Provider value={{ is404 }}>
			{children}
		</NotFoundContext.Provider>
	);
};

const AppRouter = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/shop" element={<Products />} />
				<Route path="/shop/category/:categoryName" element={<Products />} />
				<Route path="/shop/brand/:brandName" element={<Products />} />
				<Route path="/shop/*" element={<Products />} />
				<Route path="/support-us" element={<Support />} />
				<Route path="/exercise" element={<Exercise />} />
				<Route path="/exercise/about" element={<ExerciseAbout />} />
				<Route path="/exercise/morning" element={<ExerciseMorning />} />
				<Route path="/exercise/daytime" element={<ExerciseDaytime />} />
				<Route path="/exercise/evening" element={<ExerciseEvening />} />
				<Route path="/what-to-eat" element={<WhatToEat />} />
				<Route path="/what-to-eat/healthy-foods" element={<WhatToEatHealthy />} />
				<Route path="/what-to-eat/fasting-snacks" element={<WhatToEatSnacks />} />
				<Route path="/what-to-eat/fat-bomb-recipes" element={<WhatToEatRecipes />} />
				<Route path="/what-to-eat/inflammatory-foods" element={<WhatToEatInflammatory />} />
				<Route path="/how-to" element={<HowTo />} />
				<Route path="/how-to/about" element={<HowToAbout />} />
				<Route path="/how-to/notes" element={<HowToNotes />} />
				<Route path="/how-to/getting-started" element={<HowToStart />} />
				<Route path="/how-to/36-hour-timeline" element={<HowTo36 />} />
				<Route path="/info" element={<Info />}>
					<Route index element={<RedirectToFirstMD />} />
					<Route path=":markdownPath*" element={<MarkdownLoader />} />
				</Route>
                <Route path="*" element={<NotFoundProvider initialIs404={true}><NotFound /></NotFoundProvider>} />			</Routes>
		</Router>
	);
};

export default () => {
	return (
        <NotFoundProvider initialIs404={false}>
            <AppRouter />
        </NotFoundProvider>
	);
};
