import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const customBoxStyleBase = {

    position: 'relative'
};

let customBoxStyle = {
    ...customBoxStyleBase,
};

const Boxes = () => {
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, [location.pathname]);

    return (
        <div className="container mt-5">
            <div className="row">
                {/* Introduction Card */}
                <div className="col-md mb-3" style={{ position: 'relative' }}>
                    <Link to="/how-to/about" className="text-decoration-none">
                        <div className="card">
                            <img src="/assets/img/introduction.png" className="card-img-top" alt="Introduction"/>
                            <div className="card-body">
                                <h5 className="card-title">Introduction</h5>
                                <p className="card-text">Brief about the S-Carb Diet and its principles.</p>
                            </div>
                        </div>
                    </Link>
                </div>

                {/* Getting Started Card */}
                <div className="col-md mb-3" style={{ position: 'relative' }}>
                    <Link to="/how-to/getting-started" className="text-decoration-none">
                        <div className="card">
                            <img src="/assets/img/getting-started.png" className="card-img-top" alt="Getting Started"/>
                            <div className="card-body">
                                <h5 className="card-title">Getting Started</h5>
                                <p className="card-text">Initial steps and guidance on beginning the diet.</p>
                            </div>
                        </div>
                    </Link>
                </div>

                {/* Phase 1 Card */}
                <div className="col-md mb-3" style={{ position: 'relative' }}>
                    <Link to="/how-to/36-hour-timeline" className="text-decoration-none">
                        <div className="card">
                            <img src="/assets/img/36-hours.png" className="card-img-top" alt="Phase 1"/>
                            <div className="card-body">
                                <h5 className="card-title">36 Hour Fasting Journey</h5>
                                <p className="card-text">What to expect during your S-Carb 36 hour fast</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md mb-3" style={{ position: 'relative' }}>
                    <Link to="/how-to/notes" className="text-decoration-none">
                        <div className="card">
                            <img src="/assets/img/tips-tricks.png" className="card-img-top" alt="Tips and Tricks"/>
                            <div className="card-body">
                                <h5 className="card-title">Tips and Tricks</h5>
                                <p className="card-text">Notes about dietary guidelines, tips, tricks and
                                    observations.</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Boxes;